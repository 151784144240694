import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useParentDashboardContextContext } from "../../../context/ParentDashboardContext";
import { useThemeContext } from "../../../context/ThemeProvider";
import { NavOptionsWithElementInterface } from "../../../shared/type/navOptions.type";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import CustomHorizontalTabs from "../../CustomHorizontalTabs/CustomHorizontalTabs";
import Navigation from "../../Navigation/Navigation";
import CreateNewThread from "./CreateNewThread/CreateNewThread";
import ParentMessage from "./ParentMessage/ParentMessage";

const ParentMailBox = () => {
  const { parentDashboardContext } = useParentDashboardContextContext();
  const [chosenTenantId, setChosenTenantId] = useState<number>()
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [ refreshMessages, setRefreshMessages ] = useState<() => void>(() => () => {});
  const { isMobile } = useThemeContext();
  const location = useLocation();

  useEffect(() => {
    if (parentDashboardContext) {
      setChosenTenantId(parentDashboardContext.tenants[0].tenantId);
    }
  }, [parentDashboardContext]);

  const navOptions: NavOptionsWithElementInterface[] = [
    {
      link: 'messages',
      name: 'Wiadomości',
      component: <ParentMessage tenantId={chosenTenantId?.toString() || ''} setRefreshMessages={setRefreshMessages} />
    },
    // {
    //   link: 'notifications',
    //   name: 'Komunikaty',
    //   component: <ParentNotification tenantId={chosenTenantId?.toString() || ''} />
    // }
  ];

  return (
    <section className='pt-spacing-3xl pb-12 gap-spacing-3xl'>
      <div className='flex justify-between pr-spacing-3xl pl-spacing-3xl'>
        <div>
          <h2 className='font-semibold text-grayLight-900' style={{ fontSize: '30px' }}>
            Wiadomości
          </h2>
        </div>

        {location.pathname.includes('messages') && (
          <div className='fixed bottom-28 right-10 lg:flex'>
            <CustomChakraButton
              iconPosition={isMobile ? 'left' : 'right'}
              icon='plus'
              iconColor='#292524'
              hierarchy='primary'
              size={isMobile ? 'xl' : 'md'}
              buttonProps={{
                onClick: () => { setShowPopUp(true) }
              }}
            >
              {isMobile ? "Nowa" : "Nowa wiadomość"}
            </CustomChakraButton>
          </div>
        )}
      </div>

      <div className='flex flex-col'>
        <div className='pt-spacing-xl pr-spacing-4xl pb-spacing-xl pl-spacing-3xl gap-spacing-xl'>
          <CustomHorizontalTabs
            onChange={(value) => { setChosenTenantId(value) }}
            data={parentDashboardContext ? parentDashboardContext.tenants.map(tenant => ({ label: tenant.tenantName, value: tenant.tenantId })) : null}
          />
        </div>

        <div>
          <Navigation navOptions={navOptions} />
        </div>

        <div>
          <Routes>
            {navOptions.map((item) => (
              <Route key={item.link} path={`/${item.link}/*`} element={item.component} />
            ))}
          </Routes>
        </div>
      </div>

      {showPopUp && <CreateNewThread isOpen={showPopUp} onClose={() => {
        refreshMessages();
        setShowPopUp(false);
      }} />}
    </section>
  );
};

export default ParentMailBox;
