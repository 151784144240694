import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Radio,
  Stack,
  RadioGroup,
  Input
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useParams } from "react-router-dom";
import { useApi } from '../../../../../context/ApiProvider';
import { OrganisationUnitInterface, DayOffInterface } from '../../../../../shared';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import CustomInputRadio from '../../../../Forms/CustomInputRadio/CustomInputRadio';
import FormInput from '../../../../Forms/FormInput/FormInput';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initData: DayOffInterface;
}

const EditDayOff = ({ isOpen, onClose, initData }: Props) => {
  const { id: tenant } = useParams();
  const { apiTenantOrganisationUnitController, apiTenantFreeWorkingDayController } = useApi();
  const [dayOff, setDayOff] = useState<DayOffInterface>(initData);
  const [organizationUnits, setOrganizationUnits] = useState<OrganisationUnitInterface[]>([]);

  useEffect(() => {
    const fetchOrganizationUnits = async () => {
      try {
        const response = await apiTenantOrganisationUnitController('').get(`/${tenant}`);
        setOrganizationUnits(response.data);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchOrganizationUnits();
  }, [apiTenantOrganisationUnitController, tenant]);

  useEffect(() => {
    setDayOff(initData);
  }, [initData]);

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDayOff((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectChange = (selectedOptions: any) => {
    const selectedUnits = selectedOptions.map((option: any) => ({
      id: option.value,
      name: option.label
    }));
    setDayOff((prev) => ({
      ...prev,
      organisationUnits: selectedUnits
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await apiTenantFreeWorkingDayController('').put(`/${initData.id}`, {
        tenantId: tenant,
        name: dayOff.name,
        from: dayOff.from,
        to: dayOff.to,
        freeDay: dayOff.freeDay,
        organisationIds: dayOff.organisationUnits.map(unit => unit.id)
      });
      onClose();
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={handleSubmit}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-semibold text-lg">Edytuj</h2>
            <p className="font-normal text-sm">
              Dodanie dnia wolnego oznacza automatyczną nieobecność dla wybranej grupy dzieci oraz
              zwrot na ich salda.
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Sprzedaż</label>
              <RadioGroup
                value={`${dayOff.freeDay}`}
                onChange={(value) => setDayOff((prev) => ({ ...prev, freeDay: value === 'true' }))}
              >
                <Stack>
                  <CustomInputRadio
                    header="Sprzedaż otwarta"
                    message="otwarcie sprzedaży w dni, które są zwykle dniami wolnymi np. odpracowywanie dnia Ziemi w sobotę"
                  >
                    <Radio size="md" value="true" name="freeDay" colorScheme="green" />
                  </CustomInputRadio>
                  <CustomInputRadio
                    header="Sprzedaż zamknięta"
                    message="zamknięcie sprzedaży w tygodniu, np. wyjście do kina"
                  >
                    <Radio size="md" value="false" name="freeDay" colorScheme="green" />
                  </CustomInputRadio>
                </Stack>
              </RadioGroup>
            </div>
            <FormInput
              label="Nazwa"
              placeholder="Podaj nazwę"
              bottomElement={
                <p className="text-sm font-normal text-grayLight-500">
                  Ta nazwa będzie wyświetlać się w kalendarzach użytkowników.
                </p>
              }
              inputParams={{
                required: true,
                value: dayOff.name,
                name: 'name',
                onChange: updateState
              }}
            />
            <div className="flex flex-col gap-spacing-xs">
              <label className="text-sm font-medium text-grayLight-700">Klasa</label>
              <Select
                colorScheme="gray"
                isMulti
                placeholder="Wybierz klasy których dotyczą dni. Zostaw pole puste, żeby wybrać wszystkie klasy."
                value={dayOff.organisationUnits.map((singleUnit) => ({
                  label: singleUnit.name,
                  value: singleUnit.id
                }))}
                onChange={handleSelectChange}
                options={organizationUnits.map((unit) => ({
                  label: unit.name,
                  value: unit.id
                }))}
              />
            </div>
            <div className="flex flex-row items-start">
              <div className="flex basis-1/2 gap-spacing-xs">
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data od</label>
                  <Input
                    type="date"
                    required
                    value={dayOff.from}
                    name="from"
                    onChange={updateState}
                  />
                </div>
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data do</label>
                  <Input type="date" required value={dayOff.to} name="to" onChange={updateState} />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                onClick: onClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'submit'
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default EditDayOff;
