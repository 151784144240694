import { Thead, Th, Box, Text, TableContainer } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useApi } from '../../../../../context/ApiProvider';
import { DeletedElementInterface } from '../../../../../shared/type/deletedElement.type';
import {
  MealDistributionInterface,
  EditMealDistributionInterface
} from "../../../../../shared/type/mealDistribution.type";
import defaultAlertContext from '../../../../../utils/defaultAlertContext';
import CustomAlert from '../../../../CustomAlert/CustomAlert';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../../DataTable/DataTable';
import IconComponent from '../../../../IconComponent/IconComponent';
import AddEditServing from "./AddEditServing/AddEditServing";

interface ShowModalInterface {
  show: boolean,
  mode: 'add' | 'edit'
}

const Servings = () => {
  const columnHelper = createColumnHelper<MealDistributionInterface>();
  const { id: tenant } = useParams();
  const { apiTenantMealDistributionController } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [servings, setServings] = useState<MealDistributionInterface[]>([]);
  const [deleteElement, setDeleteElement] = useState<DeletedElementInterface>(defaultAlertContext);
  const [editedElement, setEditedElement] = useState<EditMealDistributionInterface>();
  const [ showModal, setShowModal ] = useState<ShowModalInterface>({
    show: false,
    mode: 'add'
  });

  const fetchServings = async () => {
    try {
      setIsLoading(true);
      const response = await apiTenantMealDistributionController('').get(`/${tenant}`);
      setServings(response.data);
    } catch (error) {
      console.warn(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchServings();
  }, []);

  useEffect(() => {
    fetchServings();
  }, [tenant]);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => {
        const name = info.getValue();

        return(
          <p className="text-grayLight-900 font-medium text-sm">
            {name}
          </p>
        )
      },
      header: "Nazwa"
    }),
    columnHelper.accessor('startHour', {
      cell: (info) => {
        const startHour = info.getValue();

        return(
          <p className="text-grayLight-600 font-normal text-sm">
            {startHour}
          </p>
        )
      },
      header: "Początek"
    }),
    columnHelper.accessor('endHour', {
      cell: (info) => {
        const endHour = info.getValue();

        return(
          <p className="text-grayLight-600 font-normal text-sm">
            {endHour}
          </p>
        )
      },
      header: 'Koniec'
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const serving = info.row.original;
        return (
          <div className="flex gap-spacing-sm">
            <button
              className="font-semibold text-sm text-grayLight-600 bg-transparent border-none"
              onClick={(e) => {
                e.preventDefault();
                setDeleteElement({
                  isOpen: true,
                  id: serving.id
                });
              }}
            >
              Usuń
            </button>
            <button className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none" onClick={() => {
              setEditedElement({
                id: serving.id,
                tenantId: Number(tenant),
                name: serving.name,
                startHour: serving.startHour,
                endHour: serving.endHour,
              });
              setShowModal({
                mode: 'edit',
                show: true
              })
            }}>
              Edytuj
            </button>
          </div>
        );
      }
    })
  ];

  return (
    <section className="Servings p-spacing-xl w-full">
      <TableContainer h="100%" flex="1" minH="500px" className="Branches__table bg-white rounded-lg pb-spacing-8xl">
        <DataTable
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base normal-case text-grayLight-900"
                      >
                        Lista wydawek
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm normal-case text-grayLight-700"
                      >
                        Tutaj możesz tworzyć i edytować wydawki jakie będą obowiązywać w oddziale.
                      </Text>
                    </Box>
                  </div>
                  <CustomChakraButton size="md" hierarchy="primary" iconPosition="left" icon="plus" buttonProps={{
                    onClick: () => {
                      setShowModal({ show: true, mode: 'add' })
                    }
                  }}>
                    Dodaj wydawkę
                  </CustomChakraButton>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={servings}
        />
      </TableContainer>
      <CustomAlert
        header="Usunąć wydawkę?"
        content="Usunięcie wydawki jest nieodwracalne."
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Usuń"
        onConfirmAction={async () => {
          try {
            await apiTenantMealDistributionController('').delete(`/${tenant}/${deleteElement.id}`);
          } catch (error) {
            console.warn(error);
          } finally {
            setDeleteElement(defaultAlertContext);
            fetchServings();
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setDeleteElement(defaultAlertContext);
          fetchServings();
        }}
        handleOpen={deleteElement.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
      />
      <AddEditServing mode={showModal.mode} editedServing={editedElement} isOpen={showModal.show} onClose={() => {
        setShowModal((prev) => ({ ...prev, show: false }));
        fetchServings();
      }} />
    </section>
  );
};

export default Servings;
