import React, { ReactNode, useMemo, useState, createContext, useContext } from 'react';
import MobileMenu from "../component/MobileMenu/MobileMenu";
import SideMenu from '../component/SideMenu/SideMenu';
import { SideMenuOptionInterface } from '../shared';
import { useThemeContext } from "./ThemeProvider";

interface Props {
  children: ReactNode;
}

export type ToggleSideNavType = 'hide' | 'minimal';

interface SideNavContextProps {
  toggleShowNav: (value: boolean) => void;
  toggleMinimalNav: (value: boolean) => void;
  toggleMode: ToggleSideNavType;
  setToggleMode: React.Dispatch<React.SetStateAction<ToggleSideNavType>>;
  setNavOptions: (newOptions: SideMenuOptionInterface[]) => void;
  showNav: boolean;
  isMinimal: boolean;
  navOptions: SideMenuOptionInterface[];
}

const SideNavContext = createContext<SideNavContextProps | undefined>(undefined);

export const useSideNavContext = () => {
  const context = useContext(SideNavContext);
  if (!context) {
    throw new Error('useSideNavContext must be used within a SideNavProvider');
  }
  return context;
};

const mobileNavOptions: SideMenuOptionInterface[] = [
  {
    header: 'Jadłospis',
    icon: 'cutlery',
    link: 'meal-plan'
  },
  {
    header: 'Obecności',
    icon: 'calendar',
    link: 'absences'
  },
  {
    header: 'Wiadomości',
    icon: 'message',
    link: 'mail-box/messages'
  },
  {
    header: 'Główna',
    icon: 'happyHomeIcon',
    link: 'main'
  }
];

export const SideNavProvider = ({ children }: Props) => {
  const [show, setShow] = useState<boolean>(true);
  const [isMinimal, setIsMinimal] = useState<boolean>(false);
  const [navOptions, setNavOptions] = useState<SideMenuOptionInterface[]>([]);
  const [toggleMode, setToggleMode] = useState<ToggleSideNavType>('hide');
  const { isMobile } = useThemeContext();

  const toggleShowNav = (value: boolean) => {
    setShow(value);
  };

  const toggleMinimalNav = (value: boolean) => {
    setIsMinimal(value);
  };

  const updateNavOptions = (newOptions: SideMenuOptionInterface[]) => {
    setNavOptions(newOptions);
  };

  const contextValue = useMemo(
    () => ({
      toggleMode,
      setToggleMode,
      toggleShowNav,
      toggleMinimalNav,
      showNav: show,
      isMinimal,
      navOptions,
      setNavOptions: updateNavOptions,
    }),
    [show, isMinimal, navOptions, toggleMode]
  );

  return (
    <SideNavContext.Provider value={contextValue}>
      <div className="flex lg:flex-row flex-col h-full w-screen">
        {/* Side nav context */}
        <div
          className={`transition-all hidden lg:flex duration-300 ease-in-out border-r border-grayLight-200 
            ${show ? (isMinimal ? 'w-20' : 'w-72') : 'w-0'} 
            ${show ? 'translate-x-0' : '-translate-x-full'}
          `}
          style={{
            overflow: 'hidden',
            transition: 'width 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
        >
          <SideMenu menuOptions={navOptions} />
        </div>

        {/* Context context */}
        <div
          style={{
            height: `${isMinimal ? 'calc(100% - 72px)' : '100%'}`,
            width: isMobile ? '100vw' : `calc(100vw - ${show ? (isMinimal ? '5rem' : '18rem') : '0'})`,
            transition: 'width 0.3s ease-in-out',
          }}
          className="flex-1 mb-52 pb-52"
        >
          {children}
        </div>
        {/* Mobile nav */}
        <div className="lg:hidden fixed bottom-0 left-0 w-screen" style={{height: '72px'}}>
          <MobileMenu menuOptions={mobileNavOptions}/>
        </div>
      </div>
    </SideNavContext.Provider>
  );
};
