import React, { ReactNode, useMemo, useState, createContext, useContext, useEffect } from 'react';

interface Props {
  children: ReactNode;
}

interface ThemeContextProps {
  toggleColorMode: () => void;
  isLightMode: boolean;
  isMobile: boolean;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within an ThemeProvider');
  }
  return context;
};

export const ThemeProvider = ({ children }: Props) => {
  const [isLightMode, setIsLightMode] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleColorMode = () => {
    setIsLightMode((prev) => !prev);
  };

  const contextValue = useMemo(
    () => ({
      toggleColorMode,
      isLightMode,
      isMobile
    }),
    [toggleColorMode, isLightMode, isMobile]
  );

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};
