import axios, { HttpStatusCode } from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import CustomAlert from "../../../../../CustomAlert/CustomAlert";
import IconComponent from "../../../../../IconComponent/IconComponent";


interface Props {
  isOpen: boolean,
  deleteId: number,
  onCancel: () => void,
  onConfirm: () => void,
}

const DeleteKid = ({ isOpen, deleteId, onCancel, onConfirm }: Props) => {
  const { apiTenantConsumerController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext()
  const { id: tenantId } = useParams<{id: string}>();

  return (
    <CustomAlert
      handleOpen={isOpen}
      icon={
        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
          <IconComponent iconName="trash" />
        </div>
      }
      header={"Usunąć konto konsumenta?"}
      content={"Usunięcie konta jest nieodwracalne."}
      cancelButton={{
        iconPosition: 'onlyText',
        size: 'lg',
        hierarchy: 'secondaryGray'
      }}
      cancelButtonText={'Anuluj'}
      confirmButton={{
        iconPosition: 'onlyText',
        size: 'lg',
        hierarchy: 'warning'
      }}
      confirmButtonText={'Usuń'}
      onConfirmAction={async () => {
        try {
          let response = await apiTenantConsumerController('').delete(`/${tenantId}/${deleteId}`);
          if (response.status === HttpStatusCode.Ok) {
            setAlertProperties({
              timeout: 2000,
              title: "Sukces",
              description: `Poprawnie usunięto konsumenta`,
              status: "success"
            });
            setShow(true);
            onConfirm();
          }
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            setAlertProperties({
              timeout: 5000,
              title: "Błąd usuwania",
              description: `Błąd: ${error.response.data}`,
              status: "warning"
            })
          }else{
            setAlertProperties({
              timeout: 5000,
              title: "Błąd",
              description: `Nie udało się usunąć konsumenta`,
              status: "error"
            });
          }
          setShow(true);

        }
      }}
      onCancelAction={onCancel} />
  )
};

export default DeleteKid;
