import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { NavOptionsWithElementInterface } from "../../../shared/type/navOptions.type";
import Navigation from "../../Navigation/Navigation";
import ChangePasswordSettings from "../../SettingsComponent/ChangePasswordSettings";
import AdminAccountSettings from "./AdminAccountSettings/AdminAccountSettings";

const AdminSettings = () => {
  const navOptions: NavOptionsWithElementInterface[] = [
    {
      link: 'account-settings',
      name: 'Ustawienia konta',
      component: <AdminAccountSettings />
    },
    {
      link: 'password-settings',
      name: 'Hasło',
      component: <ChangePasswordSettings />
    }]

  return (
    <section className="flex flex-col pt-spacing-3xl pb-12 gap-spacing-3xl">
      <div className="flex flex-col gap-6 pr-spacing-3xl pl-spacing-3xl">
        <Navigation navOptions={navOptions} />
        <Routes>
          <Route path="/" element={<Navigate to="account-settings" replace />} />
          {navOptions.map((item) => (
            <Route key={item.link} path={`/${item.link}`} element={item.component} />
          ))}
        </Routes>
      </div>
    </section>
  );};

export default AdminSettings;