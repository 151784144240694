import { Checkbox, TableContainer, Thead, Th, Divider, Box, Text, Tooltip } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from "../../../../../context/ApiProvider";
import { MealToDayDayInterface, MealToDayInterface } from "../../../../../shared/type/mealToDay.type";
import BadgeWithMessage from "../../../../BadgeWithMessage/BadgeWithMessage";
import ChooseMonths from "../../../../ChooseMonths/ChooseMonths";
import CustomAlert from "../../../../CustomAlert/CustomAlert";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../DataTable/DataTable";
import IconComponent from "../../../../IconComponent/IconComponent";
import AddEditMealPlan from "./AddEditMealPlan/AddEditMealPlan";
import DuplicateMealPlan from "./DuplicateMealPlan/DuplicateMealPlan";
import ExportMealPlanToOtherBranch from "./ExportMealPlanToOtherBranch/ExportMealPlanToOtherBranch";
import ImportMealPlanFromPdf from "./ImportMealPlanFromPdf/ImportMealPlanFromPdf";

const MealPlan = () => {
  const { apiTenantMealToDayController, apiTenantPdfMealMenuController } = useApi();
  const { setAlertProperties, setShow } = useAlertContext();
  const columnHelper = createColumnHelper<MealToDayDayInterface>();
  const { id: tenantId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mealPlans, setMealPlans] = useState<MealToDayDayInterface[]>([]);
  const [ duplicateMealPlanDate, setDuplicateMealPlanDate ] = useState<string>('');
  const [deletedElementId, setDeletedElementId] = useState<number>(0);
  const [ deleteElementType, setDeleteElementType ] = useState<'pdf' | 'text'>('text');

  const today = new Date();

  const [ showPopUp, setShowPopUp ] = useState<{
    exportMealPlanToOtherBranch: boolean,
    addEditMealPlan: boolean,
    duplicateMealPlan: boolean,
    deleteElement: boolean,
    importPdf: boolean
  }>({
    exportMealPlanToOtherBranch: false,
    addEditMealPlan: false,
    duplicateMealPlan: false,
    deleteElement: false,
    importPdf: false
  });
  const [mealPlanDate, setMealPlanDate] = useState<{ year: number; month: number, day: number }>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });

  const fetchMealPlans = async () => {
    try {
      setIsLoading(true);
      const response: AxiosResponse<MealToDayInterface> = await apiTenantMealToDayController('').post('', {
        tenantId: Number(tenantId),
        year: mealPlanDate.year,
        month: mealPlanDate.month,
      });
      setMealPlans(response.data.days);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadMealPlanPdf = async (pdfId: number) => {
    try {
      const response = await apiTenantPdfMealMenuController('').get(`/${tenantId}/${pdfId}/export`, {
        responseType: 'blob',
      });

      if (response.data) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, '_blank');

      }
    } catch (error: any) {
      console.error('Error downloading PDF:', error);
    }
  };

  const columns = [
    columnHelper.display({
      id: 'select',
      header: () => <Checkbox colorScheme="customOrange" />,
      cell: () => <Checkbox colorScheme="customOrange" />,
    }),
    columnHelper.accessor('when', {
      cell: (info) => {
        const { when } = info.row.original;
        const date = new Date(when);

        const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
        const dayName = date.toLocaleDateString('pl-PL', options);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');

        return (
          <div className="flex flex-col">
            <p className="text-grayLight-900 font-medium text-sm">{dayName}</p>
            <p className="text-grayLight-600 font-normal text-sm">{`${day}.${month}`}</p>
          </div>
        );
      },
      header: "Dzień",
    }),
    columnHelper.accessor("mealsToCourse", {
      cell: (info) => {
        const { mealsToCourse, workingDay, pdfMenu } = info.row.original;

        if (!workingDay) {
          return <p>Wolne</p>;
        }

        if (pdfMenu !== null) {
          return <button className='flex gap-spacing-xs pt-2.5 pr-3 pb-2.5 pl-3' onClick={() => {
            downloadMealPlanPdf(pdfMenu?.id)
          }}>
            <IconComponent iconName='pdf'/>
            <p className='text-sm font-semibold text-grayWarm-950'>Zobacz jadłospis</p>
          </button>
        }

        return (
          <div
            className="flex flex-col"
            style={{ height: 60 * (mealsToCourse?.length || 0) + 'px'}}
          >
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: 1 / (mealsToCourse?.length || 0) * 100 + '%'}}
                  className={`h-full text-grayLight-900 text-sm font-medium pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                  {meal.courseName.charAt(0).toUpperCase() + meal.courseName.slice(1)}
                </div>
              </React.Fragment>
            ))}
          </div>

        );
      },
      header: "Kategoria posiłków"
    }),
    columnHelper.accessor("mealsToCourse", {
      cell: (info) => {
        const mealsToCourse = info.getValue();

        return (
          <div
            className="flex flex-col h-full"
            style={{ height: 60 * (mealsToCourse?.length || 0) + 'px'}}>
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: 1 / (mealsToCourse?.length || 0) * 100 + '%'}}
                  className={`flex-grow flex flex-col justify-between pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                  <p className="text-grayLight-600 text-sm font-medium">{meal.mealDto?.name || "Brak posiłku"}</p>
                </div>
              </React.Fragment>
            ))}
          </div>
        );
      },
      header: "Danie"
    }),
    columnHelper.accessor("mealsToCourse", {
      cell: (info) => {
        const mealsToCourse = info.getValue();

        return (
          <div
            className="flex flex-col"
            style={{ height: 60 * (mealsToCourse?.length || 0) + 'px'}}>
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: 1 / (mealsToCourse?.length || 0) * 100 + '%'}}
                  className={`basis-1/${mealsToCourse?.length} text-grayLight-600 flex-wrap flex text-sm font-medium gap-spacing-md pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                  {Number(meal.mealDto?.allergens.length) > 0 ? meal.mealDto?.allergens.map(allergen => (
                    <BadgeWithMessage tooltipLabel={<p
                      className="rounded-lg pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg text-sm text-grayLight-900 font-medium">{allergen.name}</p>}>{`${allergen.formalNumber}`}</BadgeWithMessage>
                  )) : 'Brak alergenów'}
                </div>
              </React.Fragment>
            ))}
          </div>
        );
      },
      header: 'Alergeny',
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: (info) => {
        const { workingDay, mealsToCourse, pdfMenu } = info.row.original;

        const handleDelete = (type: 'pdf' | 'text', id: number) => {
          setDeleteElementType(type);
          setDeletedElementId(id);
          setShowPopUp((prev) => ({ ...prev, deleteElement: true }));
        };

        if (pdfMenu !== null) {
          return (
            <div className="flex items-center justify-center pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md">
                <button
                className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none"
                onClick={() => handleDelete('pdf', pdfMenu.id)}
              >
                Usuń
              </button>
            </div>
          );
        }

        if (!workingDay) return null;

        return (
          <div className="flex flex-col" style={{ height: 60 * (mealsToCourse?.length || 0) + 'px' }}>
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: `${100 / (mealsToCourse?.length || 1)}%` }}
                  className="flex items-center justify-center pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md"
                >
                  <button
                    className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none"
                    onClick={() => handleDelete('text', meal?.mealToDayId || 0)}
                  >
                    Usuń
                  </button>
                </div>
              </React.Fragment>
            ))}
          </div>
        );
      },
    }),

    columnHelper.display({
      id: "actions",
      header: "",
      cell: (info) => {
        const { workingDay, when } = info.row.original;

        if (!workingDay) {
          return;
        } else {
          return (
            <div className="flex gap-spacing-sm">
              <div className="cursor-pointer"
                   onClick={() => {
                     setDuplicateMealPlanDate(when);
                     setShowPopUp((prev) => ({ ...prev, duplicateMealPlan: true}))
              }}>
                <Tooltip hasArrow label="Duplikuj jadłospis" background={"#FFF7E6"} color={"#101828"}>
                  <IconComponent iconName="copy" />
                </Tooltip>
              </div>
            </div>
          )
        }
      },
    }),
  ];

  useEffect(() => {
    fetchMealPlans();
  }, [tenantId, mealPlanDate]);

  return (
    <section className="MealPlan p-spacing-xl w-full">
      <TableContainer h="100%" flex="1" minH="500px" className="Branches__table bg-white rounded-lg pb-spacing-8xl">
        <DataTable
          filterComponent={
            <div className="flex gap-spacing-xl">
              <ChooseMonths mode='month' currentDate={mealPlanDate} setDate={setMealPlanDate} />
              <CustomChakraButton size="sm" hierarchy="tertiaryColor" iconPosition="onlyText" icon="plus" buttonProps={{
                onClick: () => {
                  setShowPopUp((prev) => ({ ...prev, exportMealPlanToOtherBranch: true }))
                }
              }}>
                Eksportuj do oddziału
              </CustomChakraButton>
            </div>
          }
          disableFilters={true}
          data={mealPlans}
          columns={columns}
          isLoading={isLoading}
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base normal-case text-grayLight-900"
                      >
                        Jadłospisy
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm normal-case text-grayLight-700"
                      >
                        Tutaj możesz tworzyć i edytować jadłospis obowiązujący w oddziale
                      </Text>
                    </Box>
                  </div>
                  <div className="flex gap-spacing-xl">
                    <CustomChakraButton hierarchy="secondaryColor" size="md" iconPosition="onlyText" buttonProps={{
                      onClick: () => {
                        setShowPopUp((prev) => ({ ...prev, importPdf: true }));
                      }
                    }}>
                      Załaduj jadłospis PDF
                    </CustomChakraButton>
                    <CustomChakraButton hierarchy="primary" size="md" icon="plus" iconPosition="left" buttonProps={{
                      onClick: () => {
                        setShowPopUp((prev) => ({ ...prev, addEditMealPlan: true }));
                      }
                    }}>
                      Stwórz jadłospis
                    </CustomChakraButton>
                  </div>
                </div>
              </Th>
            </Thead>
          }
        />
        {showPopUp.exportMealPlanToOtherBranch ? (
          <ExportMealPlanToOtherBranch
            isOpen={showPopUp.exportMealPlanToOtherBranch}
            onClose={() => {
              setShowPopUp((prev) => ({ ...prev, exportMealPlanToOtherBranch: false }));
              fetchMealPlans();
            }}
            year={mealPlanDate.year}
            month={mealPlanDate.month}
          />
        ) : null}
        {showPopUp.addEditMealPlan ? (
          <AddEditMealPlan
            isOpen={showPopUp.addEditMealPlan}
            mode='add'
            onClose={() => {
              setShowPopUp((prev) => ({ ...prev, addEditMealPlan: false }));
              fetchMealPlans();
            }}
          />
        ) : null}
        {showPopUp.duplicateMealPlan ? (
          <DuplicateMealPlan
            isOpen={showPopUp.duplicateMealPlan}
            onClose={() => {
              setShowPopUp((prev) => ({ ...prev, duplicateMealPlan: false }));
              fetchMealPlans();
            }}
            dateWhen={duplicateMealPlanDate}
          />
        ) : null}
        {showPopUp.importPdf ? (
          <ImportMealPlanFromPdf
            isOpen={showPopUp.importPdf}
            onClose={() => {
              setShowPopUp((prev) => ({ ...prev, importPdf: false }));
              fetchMealPlans();
            }}
          />
        ) : null}
        {showPopUp.deleteElement && (
          <CustomAlert
            header="Usunąć jadłospis?"
            content="Usunięcie jadłospisu jest nieodwracalne."
            confirmButton={{
              iconPosition: 'onlyText',
              size: 'lg',
              hierarchy: 'warning'
            }}
            confirmButtonText="Usuń"
            onConfirmAction={async () => {
              try {
                let response;
                if (deleteElementType === 'text') {
                  response = await apiTenantMealToDayController('delete-assignment').post('', {
                    id: deletedElementId,
                  });
                } else {
                  response = await apiTenantPdfMealMenuController('').delete('', {
                    params: {
                      mealMenuId: deletedElementId,
                      tenantId: Number(tenantId),
                    },
                  });
                }

                if (response.status === HttpStatusCode.Ok) {
                  setAlertProperties({
                    timeout: 2000,
                    title: 'Sukces',
                    description: `Poprawnie usunięto jadłosps`,
                    status: 'success',
                  });
                  setShow(true);
                  fetchMealPlans();
                }
              } catch (error: any) {
                console.error(error);
                setAlertProperties({
                  timeout: 2000,
                  title: 'Błąd',
                  description: `Nie udało się usunąć jadłospisu: ${error.response.data.errorMessage}`,
                  status: 'error',
                });
                setShow(true);
              } finally {
                setDeletedElementId(0);
                setShowPopUp((prev) => ({ ...prev, deleteElement: false }));
              }
            }}
            cancelButton={{
              iconPosition: 'onlyText',
              size: 'lg',
              hierarchy: 'secondaryGray'
            }}
            cancelButtonText="Anuluj"
            onCancelAction={() => {
              setShowPopUp((prev) => ({ ...prev, deleteElement: false }));
            }}
            handleOpen={showPopUp.deleteElement}
            icon={
              <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
                <IconComponent iconName="trash" />
              </div>
            }
          />)}
      </TableContainer>
    </section>
      );
};

      export default MealPlan;
