import { TableContainer, Thead, Th, Box, Text, Checkbox, Tr, Flex } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useApi } from "../../../../../context/ApiProvider";
import { PaginationInterface } from "../../../../../shared/type/paginationInterface.type";
import { ParentInterface, PaginatedParentInterface } from "../../../../../shared/type/parent.type";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../DataTable/DataTable";
import Pagination from "../../../../Pagination/Pagination";
import AddParent from "./AddParent/AddParent";
import ImportParentsFromFilePopUp from "./ImportParentsFromFilePopUp/ImportParentsFromFilePopUp";

const ParentsTable = () => {
  const location = useLocation();
  const columnHelper = createColumnHelper<ParentInterface>();
  const { apiTenantParentController } = useApi();
  const { id: tenantId } = useParams();
  const [parents, setParents] = useState<ParentInterface[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<{
    showImportParentsFromFilePopUp: boolean;
    showAddParentPopUp: boolean
  }>({
    showImportParentsFromFilePopUp: false,
    showAddParentPopUp: false,
  });
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 30,
    maxPageNumber: 0
  });

  const handleShowPopUpValue = (name: string, value: boolean) => {
    setShowPopUp((prev) => ({ ...prev, [name]: value }))
  };

  const fetchParents = async () => {
    setIsLoading(true)
    try {
      const response: AxiosResponse<PaginatedParentInterface> = await apiTenantParentController('paged-parent-list').post('',
        {
          pageNumber: page.pageNumber,
          pageSize: page.pageSize,
          tenantId,
        })
      const { data, next, hasNextPage, totalCount } = response.data;
      setParents(data);
      setActualPage({ next, hasNextPage, totalCount });
      setPage((prev) => ({
        ...prev,
        maxPageNumber: Math.ceil(totalCount / page.pageSize),
      }));
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    columnHelper.display({
      id: 'select',
      header: () => (
        <Checkbox
          colorScheme="customOrange"
        />
      ),
      cell: ({ }) => (
        <Checkbox
          colorScheme="customOrange"
        />
      )
    }),
    columnHelper.accessor('name', {
      cell: (info) => {
        const { name } = info.row.original;

        return (
          <p className='text-grayLight-900 font-medium text-sm underline underline-offset-4'>
            {name}
          </p>
        );
      },
      header: 'Nazwisko imię'
    }),
    columnHelper.accessor('login', {
      cell: (info) => {
        const login = info.getValue();

        return (
          <p className='text-grayLight-900 font-medium text-sm'>
            {login}
          </p>
        );
      },
      header: 'Adres email / login'
    }),
    columnHelper.accessor('phone', {
      cell: (info) => {
        const phone = info.getValue();

        return (
          <p className='text-grayLight-900 font-normal text-sm'>
            {phone ? phone : 'Brak'
            }</p>
        );
      },
      header: 'Telefon'
    }),
    columnHelper.accessor('children', {
      cell: (info) => {
        const children = info.getValue();

        const newPath = (kidId: number) => location.pathname.replace('parents/parents-table', `children/single-kid/${kidId}/data`);

        return (
          <ul>
            {children.map(kid => (
              <li className="text-grayLight-900 font-medium text-sm underline underline-offset-4 pt-1 pb-1">
                <Link to={newPath(kid.id)}>
                  {kid.lastName} {kid.firstName}
                </Link>
              </li>
            ))}
          </ul>
        );
      },
      header: "Przypisane dzieci"
    }),
    columnHelper.accessor('bankAccountPresent', {
      cell: (info) => {
        const bankAccountNumber = info.getValue();

        return (
          <p className="text-grayLight-900 font-normal text-sm">
            {bankAccountNumber ? 'tak' : 'nie'}
          </p>
        );
      },
      header: "Numer konta"
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const { id } = info.row.original;

        const newPath = location.pathname.replace('parents-table', `single-parent/${id}/list-of-orders`);

        return (
          <div className="flex gap-spacing-sm">
            <Link to={newPath}>
              <button
                className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
                Otwórz
              </button>
            </Link>
          </div>
        );
      }
    })
  ];

  useEffect(() => {
    fetchParents()
  }, [page.pageNumber, tenantId]);

  const handlePageChange = (pageNum: number) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pageNum,
    }));
  };

  return (
    <>
      <section className="ParentsTable p-spacing-xl w-full pb-28">
        <Flex direction="column" className="Branches__table bg-white rounded-lg">
          <TableContainer h="100%" flex="1" minH="500px">
            <DataTable
            disableFilters={true}
            filterComponent={
              <CustomChakraButton size='sm' hierarchy='tertiaryColor' iconPosition='onlyText' buttonProps={{
                disabled: true
              }}>Usuń zaznaczone</CustomChakraButton>
            }
            extraThead={
              <Thead>
                <Tr>
                  <Th colSpan={columns.length + 1}>
                    <div className="w-full flex items-center justify-between">
                      <div className="flex flex-col basis-4/5">
                        <Box>
                          <Text
                            whiteSpace="normal"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            className="font-semibold text-base normal-case text-grayLight-900"
                          >
                            Rodzice
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            whiteSpace="normal"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            className="font-normal text-sm normal-case text-grayLight-700"
                          >
                            Lista rodziców
                          </Text>
                        </Box>
                      </div>
                      <div className="flex gap-spacing-lg">
                        <CustomChakraButton
                          size="md"
                          hierarchy="linkGray"
                          iconPosition="left"
                          icon="import"
                          buttonProps={{
                            onClick: () => {
                              handleShowPopUpValue('showImportParentsFromFilePopUp', true);
                            },
                          }}
                        >
                          Importuj listę
                        </CustomChakraButton>
                        <CustomChakraButton
                          size="md"
                          hierarchy="primary"
                          iconPosition="left"
                          icon="plus"
                          buttonProps={{
                            onClick: () => {
                              handleShowPopUpValue('showAddParentPopUp', true);
                            },
                          }}
                        >
                          Dodaj rodzica
                        </CustomChakraButton>
                      </div>
                    </div>
                  </Th>
                </Tr>
              </Thead>
            }
            columns={columns}
            isLoading={isLoading}
            data={parents}
          />
        </TableContainer>
          <Pagination
            pageNumber={page.pageNumber}
            maxPageNumber={page.maxPageNumber}
            hasNextPage={actualPage.hasNextPage}
            onPageChange={handlePageChange}
          />
        </Flex>
      </section>
      {showPopUp.showImportParentsFromFilePopUp ? (
        <ImportParentsFromFilePopUp
          isOpen={showPopUp.showImportParentsFromFilePopUp}
          onClose={() => {
            handleShowPopUpValue('showImportParentsFromFilePopUp', false);
            fetchParents();
          }} />
      ) : null}
      {showPopUp.showAddParentPopUp ? (
        <AddParent
          isOpen={showPopUp.showAddParentPopUp}
          onClose={() => {
            handleShowPopUpValue('showAddParentPopUp', false);
            fetchParents();
          }} />
      ) : null}
    </>
  );
};

export default ParentsTable;
