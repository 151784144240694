import { useEffect } from "react";
import { useParentDashboardContextContext } from "../../../../context/ParentDashboardContext";
import { OrderType } from "../ParentOrder";

interface Props {
  updateOrderType: (type: OrderType) => void;
}

const ChooseOrderType = ({ updateOrderType }: Props) => {
  const { setBasketStatus } = useParentDashboardContextContext();

  useEffect(() => {
    setBasketStatus({
      forceShow: false,
      show: false,
    })
  }, []);

  return (
    <div className="flex flex-col gap-spacing-6xl lg:pb-spacing-6xl pr-spacing-3xl pl-spacing-3xl">
      <div className="flex flex-col items-center justify-center gap-spacing-lg">
        <h2 className='text-2xl font-semibold text-grayLight-900'>Wybierz zamówienie</h2>
        <p className='font-normal text-grayLight-600' style={{ fontSize: '16px' }}>Wybierz czy chcesz zamówić posiłki na cały miesiąc lub skonfiguruj jadłospis na każdy dzień.</p>
      </div>
      <div className="flex lg:flex-row items-center flex-col gap-10 justify-around">
        <button
          onClick={() => {
            updateOrderType('period')
          }}
          className='ChooseSchool__schoolTab border box-border rounded-xl p-spacing-xl border-brand-300 max-w-96'>
          <p className='text-grayLight-900 text-lg font-semibold text-wrap'>
            Zamówienie okresowe
          </p>
          <p className='text-grayLight-700 font-normal text-wrap' style={{ fontSize: '16px' }}>
            Łatwo zamów wybrane posiłki na wybrany okres, na przykład, na cały miesiąc
          </p>
        </button>
        <button
          onClick={() => {
            updateOrderType('day')
          }}
          className='ChooseSchool__schoolTab border box-border rounded-xl p-spacing-xl border-brand-300 max-w-96'>
          <p className='text-grayLight-900 text-lg font-semibold text-wrap'>Konfiguracja jadłospisu</p>
          <p className='text-grayLight-700 font-normal text-wrap' style={{ fontSize: '16px' }}>
            Skonfiguruj indywidualny jadłospis dziecka na każdy dzień
          </p>
        </button>
      </div>
    </div>
  )
};

export default ChooseOrderType;