import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { SideMenuOptionInterface } from '../../shared';
import IconComponent from '../IconComponent/IconComponent';

interface Props {
  menuOption: SideMenuOptionInterface;
  isMinimal: boolean;
}

const SideMenuButton = ({ menuOption, isMinimal }: Props) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showText, setShowText] = useState(!isMinimal);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isMinimal) {
      timer = setTimeout(() => setShowText(true), 300);
    } else {
      setShowText(false);
    }

    return () => clearTimeout(timer);
  }, [isMinimal]);

  useEffect(() => {
    let sectionIsChosen: boolean = false;

    if(!menuOption.children) return

    for (const option of menuOption.children) {
      if(location.pathname.includes(option.link)){
        sectionIsChosen = true;
        break;
      }
    }

    setIsOpen(sectionIsChosen);
  }, [location.pathname]);

  const buttonChildren = () =>
    menuOption.children?.map((option) => (
      <Link key={option.link} className="flex rounded-lg w-full" to={option.link}>
        <div className={`${location.pathname.includes(option.link) && 'bg-brand-100'} grid font-semibold grid-cols-[auto,1fr] items-center p-4 rounded-lg cursor-pointer hover:bg-brand-100 w-full pr-spacing-lg pl-spacing-lg pt-spacing-md pb-spacing-md`}>
          {showText && <div className="ml-2">{option.header}</div>}
        </div>
      </Link>
    ));

  return (
    <div
      className={`SideMenuButton grid w-full gap-spacing-md ${isMinimal ? 'SideMenuButton--minimal' : ''}`}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div className="flex rounded-lg w-full">
        <div
          className="grid grid-cols-[auto,1fr,auto] items-center p-4 rounded-lg cursor-pointer hover:bg-brand-100 w-full pr-spacing-lg pl-spacing-lg pt-spacing-md pb-spacing-md">
          <div className="w-6 h-6 flex items-center justify-center">
            <IconComponent iconName={menuOption.icon} />
          </div>
          {menuOption.children ? (
            <div
              className={`ml-2 transition-opacity font-semibold duration-300 ease-in-out ${showText ? 'opacity-100' : 'hidden'}`}>
              {menuOption.header}
            </div>
          ) : (
            <Link key={menuOption.link} className={`flex rounded-lg w-full ${showText ? 'opacity-100' : 'hidden'}`}
                  to={menuOption.link}>
              <div className="ml-2 font-semibold">{menuOption.header}</div>
            </Link>
          )}
          {menuOption.children && (
            <div className="w-6 h-6 flex items-center justify-center ml-auto">
              <IconComponent iconName={isOpen ? 'chevronUp' : 'chevronDown'} />
            </div>
          )}
        </div>
      </div>
      {menuOption.children && isOpen && (
        <div className="SideMenuButton__childrenContainer grid gap-spacing-xs">
          {buttonChildren()}
        </div>
      )}
    </div>

  );
};

export default SideMenuButton;
