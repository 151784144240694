import React from "react";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import CustomAlert from "../../../CustomAlert/CustomAlert";
import IconComponent from "../../../IconComponent/IconComponent";

interface Props {
  isOpen: boolean;
  setDeleteMessagePopUp:  React.Dispatch<React.SetStateAction<{   isOpen: boolean,    threadId: number }>>;
  threadId: number;
  onFinish: () => void;
}

export const defaultDeleteMessagePopUpContext = {
  isOpen: false,
  threadId: 0
}

const DeleteMessageAlert = ({ isOpen, threadId, setDeleteMessagePopUp, onFinish }: Props) => {
  const { apiParentMessageController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();

  return(
    <CustomAlert
      handleOpen={isOpen}
      icon={
        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
          <IconComponent iconName="trash" color='#D92D20' />
        </div>
      }
      header={`Usunąć wiadomość?`}
      content="Usunięcie wiadomości nieodwracalne."
      cancelButtonText="Anuluj"
      cancelButton={{
        iconPosition: 'onlyText',
        size: 'lg',
        hierarchy: 'secondaryGray'
      }}
      confirmButtonText="Usuń"
      confirmButton={{
        iconPosition: 'onlyText',
        size: 'lg',
        hierarchy: 'warning'
      }}
      onCancelAction={() => {
        setDeleteMessagePopUp(defaultDeleteMessagePopUpContext);
      }}
      onConfirmAction={async () => {
        try {
          await apiParentMessageController('thread').delete(``, {
            params: {
              threadId: threadId
            }
          });
          setAlertProperties({
            status: 'success',
            timeout: 5000,
            description: 'Wiadomość usunięta',
            title: 'Sukces'
          });
          setShow(true);
        } catch (error) {
          setAlertProperties({
            status: 'error',
            timeout: 5000,
            description: 'Nie udało się usunąć wiadomości',
            title: 'Błąd'
          });
          setShow(true);
        } finally {
          onFinish()
          // fetchMessages(currentPage);
          setDeleteMessagePopUp(defaultDeleteMessagePopUpContext);
        }
      }}
    />
  )
};

export default DeleteMessageAlert;