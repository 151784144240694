import { Thead, Th, Box, Text, TableContainer } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useApi } from '../../../../context/ApiProvider';
import { AllergenInterface } from '../../../../shared/type/allergen.type';
import { DataTable } from '../../../DataTable/DataTable';

const Allergens = () => {
  const { apiTenantAllergenController } = useApi();
  const { id: tenantId } = useParams();
  const [allergens, setAllergens] = useState<AllergenInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const columnHelper = createColumnHelper<AllergenInterface>();

  const columns = [
    columnHelper.accessor('id', {
      cell: (info) => {
        const { formalNumber } = info.row.original;
        return <p className="text-grayLight-600 font-normal text-sm">{formalNumber}</p>;
      },
      header: 'Lp.'
    }),
    columnHelper.accessor('name', {
      cell: (info) => {
        const {  name } = info.row.original;
        return (
          <p className="font-medium text-sm text-grayLight-900">
            {name}
          </p>
        );
      },
      header: 'Nazwa'
    })
  ];

  const fetchAllergens = async () => {
    setIsLoading(true);
    try {
      const response = await apiTenantAllergenController('').get(`/${tenantId}`);
      setAllergens(response.data);
    } catch (error) {
      console.warn(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if(tenantId === '0') return
    fetchAllergens();
  }, [tenantId]);

  return (
    <section className="SalesSystem p-spacing-xl w-full h-full">
    <TableContainer h="100%" overflowY="auto" className="SalesSystem__table bg-white rounded-lg">
      <DataTable
        extraThead={
          <Thead>
            <Th colSpan={columns.length}>
              <div className="w-full flex items-center justify-between">
                <div className="flex flex-col basis-4/5">
                  <Box>
                    <Text
                      whiteSpace="normal"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      className="font-semibold text-base normal-case text-grayLight-900"
                    >
                      Lista alegrenów
                    </Text>
                  </Box>
                </div>
              </div>
            </Th>
          </Thead>
        }
        columns={columns}
        isLoading={isLoading}
        data={allergens}
      />
    </TableContainer>
    </section>
  );
};

export default Allergens;
