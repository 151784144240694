import React, { useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useParentDashboardContextContext } from "../../../context/ParentDashboardContext";
import { useParentOrderContextContext } from "../../../context/ParentOrderContext";
import IconComponent from "../../IconComponent/IconComponent";
import ChooseOrderType from "./ChooseOrderType/ChooseOrderType";
import ChooseSchool from "./ChooseSchool/ChooseSchool";
import OrderOnDays from "./OrderOnDays/OrderOnDays";
import OrderOnPeriodOfTime from "./OrderOnPeriodOfTime/OrderOnPeriodOfTime";
import './ParentOrder.css';
import OrderSummary from "./OrderSummary/OrderSummary";

type StepType = 'choose-school' | 'choose-order-type' | 'order-on-days' | 'order-on-period';

export type OrderType = 'day' | 'period';

const ParentOrder = () => {
  const { parentDashboardContext } = useParentDashboardContextContext();
  const { setTenantId } = useParentOrderContextContext();

  const [step, setStep] = useState<StepType>('choose-school');
  const [orderType, setOrderType] = useState<OrderType>();
  const navigate = useNavigate();
  const location = useLocation();

  let lastStep: StepType = 'choose-school';

  function changeStep(stepName: StepType) {
    lastStep = step;
    setStep(stepName);
  }

  function backToLastStep() {
    if (location.pathname.includes('summary')) {
      navigate('/parent/order');
      setStep('choose-order-type');
    } else {
      setStep(lastStep);
    }
  }

  const getCurrentStep = (stepName: StepType) => {
    if (!parentDashboardContext) {
      return <p></p>;
    }

    switch (stepName) {
      case 'choose-school':
        return <ChooseSchool parentDashboardContext={parentDashboardContext} updateTenantId={updateTenantId}/>;
      case 'choose-order-type':
        return <ChooseOrderType updateOrderType={updateOrderType}/>;
      case 'order-on-days':
        return <OrderOnDays/>;
      case 'order-on-period':
        return <OrderOnPeriodOfTime/>;
      default:
        return <p>Błąd</p>;
    }
  };

  const stepController = {
    back: backToLastStep,
    change: changeStep,
    getCurrentStep: getCurrentStep,
  }

  function updateTenantId(id: number) {
    setTenantId(id);
    stepController.change('choose-order-type');
  }

  function updateOrderType(type: OrderType) {
    setOrderType(type);
    let nextStep: StepType = "order-on-days";

    if (type === 'period') nextStep = 'order-on-period';

    stepController.change(nextStep);
  }

  return (
    <section className="Order flex flex-col h-full bg-grayLight-50">
      {step !== 'choose-order-type' && (
        <div className='pl-spacing-3xl pr-spacing-3xl'>
          <button
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
            className='flex pt-spacing-xl pb-spacing-xl gap-spacing-sm'
            onClick={() => {
              stepController.back();
            }}
          >
            <IconComponent iconName='arrowLeft' />
            <p className='text-grayWarm-950 font-semibold' style={{ fontSize: '16px' }}>Wróć</p>
          </button>
        </div>
      )}
      <div className='flex items-start pt-spacing-xl lg:pt-spacing-9xl lg:p-0 lg:items-center justify-center w-full h-full'>
        <Routes>
          <Route path="/summary" element={<OrderSummary />} />
          <Route path="/*" element={getCurrentStep(step)} />
        </Routes>
      </div>
    </section>
  );
};

export default ParentOrder;
