import IconComponent from "../component/IconComponent/IconComponent";

const GetButtonMessage = ({ absencePossible, presence }: {absencePossible: boolean, presence: boolean }) => {

  let message = <></>;
  let isMessage = false;

  if (!absencePossible && presence) {
    message = <>Termin zgłaszania <br /> nieobecności minął</>
    isMessage = true
  }

  if (absencePossible && !presence) {
    message = <>Odwołany posiłek</>;
    isMessage = true
  }

  if (!isMessage) return <p className="text-sm font-normal text-transparent">.</p>

  return <div className="flex items-center gap-spacing-xs grow">
    <IconComponent iconName="alertCircle" color='#D92D20' />
    <p className="text-sm font-normal text-error-500">{message}</p>
  </div>;
};

export default GetButtonMessage;