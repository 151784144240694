const ShowPasswordIcon = ({ className, color }: { className: string, color: string }) => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M1.81509 7.53488C1.71295 7.37315 1.66188 7.29229 1.63329 7.16756C1.61182 7.07387 1.61182 6.92613 1.63329 6.83244C1.66188 6.70771 1.71295 6.62685 1.81509 6.46512C2.65915 5.12863 5.17155 1.75 9.0003 1.75C12.8291 1.75 15.3415 5.12863 16.1855 6.46512C16.2877 6.62685 16.3387 6.70771 16.3673 6.83244C16.3888 6.92613 16.3888 7.07387 16.3673 7.16756C16.3387 7.29229 16.2877 7.37315 16.1855 7.53488C15.3415 8.87137 12.8291 12.25 9.0003 12.25C5.17155 12.25 2.65915 8.87137 1.81509 7.53488Z"
      stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M9.0003 9.25C10.2429 9.25 11.2503 8.24264 11.2503 7C11.2503 5.75736 10.2429 4.75 9.0003 4.75C7.75766 4.75 6.7503 5.75736 6.7503 7C6.7503 8.24264 7.75766 9.25 9.0003 9.25Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>


);

export default ShowPasswordIcon;
