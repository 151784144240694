import { PaymentType } from "../shared/type/parentOrderHistory.type";

const paymentTypeTranslations: Record<PaymentType, string> = {
  [PaymentType.SALDO]: 'Płatność z salda',
  [PaymentType.CASH]: 'Gotówka',
  [PaymentType.PAYMENT_STARTED]: 'Do zapłacenia',
  [PaymentType.TPAY]: 'T-Pay',
};

const translatePaymentType = (paymentType: PaymentType): string => {
  return paymentTypeTranslations[paymentType];
};

export default translatePaymentType;