import { TableContainer, Thead, Th, Box, Text, Checkbox } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useApi } from "../../../../../context/ApiProvider";
import { useEditTeacherOrEmployeeContext } from "../../../../../context/EditTeacherOrEmployeeProvider";
import { AddEmployeeOrTeacherAlertInterface } from "../../../../../shared/type/addEmployeeOrTeacherAlert.type";
import { ConsumerInterface } from "../../../../../shared/type/consumer.type";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../DataTable/DataTable";
import AddEmployeeOrTeacher from "../../AddEmployeeOrTeacher/AddEmployeeOrTeacher";
import DeleteEmployeeOrTeacher from "../../DeleteEmployeeOrTeacher/DeleteEmployeeOrTeacher";
import EditStackOfUser from "../../EditStackOfUser/EditStackOfUser";

const defaultDeleteElement = {
  id: 0,
  alertIsOpen: false
}

const EmployeesTable = () => {
  const location = useLocation();
  const { setId, fetchUsers, setMode} = useEditTeacherOrEmployeeContext();
  const columnHelper = createColumnHelper<ConsumerInterface>();
  const [employees, setEmployees] = useState<ConsumerInterface[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { apiTenantSpecialConsumerController } = useApi();
  const [deleteElement, setDeleteElement] = useState<{
    id: number,
    alertIsOpen: boolean
  }>(defaultDeleteElement);
  const [addEmployeeOrTeacherAlert, setAddEmployeeOrTeacherAlert] = useState<AddEmployeeOrTeacherAlertInterface>({
    mode: 'teacher',
    isOpen: false,
  });
  const [ showEditStackPopUp, setShowEditStackPopUp] = useState<boolean>(false);
  const [ selectedUsers, setSelectedUsers] = useState<number[]>([]);

  const fetchEmployee = async () => {
    try {
      setIsLoading(true);
      const response = await apiTenantSpecialConsumerController('employee-list').get(`/${id}`);
      setEmployees(response.data);
      console.log(response.data);
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }

  const handleCheckboxChange = (userId: number, isChecked: boolean) => {
    setSelectedUsers((prev) =>
      isChecked ? [...prev, userId] : prev.filter((id) => id !== userId)
    );
  };

  const handleMasterCheckboxChange = (isChecked: boolean) => {
    if (isChecked) {
      const allUserIds = employees.map(employee => employee.userId);
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
    }
  };

  const columns = [
    columnHelper.display({
      id: 'select',
      header: () => (
        <Checkbox
          colorScheme="customOrange"
          isChecked={selectedUsers.length === employees.length}
          onChange={(e) => handleMasterCheckboxChange(e.target.checked)}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          colorScheme="customOrange"
          isChecked={selectedUsers.includes(row.original.userId)}
          onChange={(e) => handleCheckboxChange(row.original.userId, e.target.checked)}
        />
      )
    }),
    columnHelper.accessor('lastName', {
      cell: (info) => {
        const { lastName, firstName } = info.row.original;

        return (
          <p className='text-grayLight-900 font-medium text-sm underline underline-offset-4'>
            {lastName} {firstName}
          </p>
        );
      },
      header: 'Nazwisko Imię'
    }),
    columnHelper.accessor('login', {
      cell: (info) => {
        const login = info.getValue();

        return (
          <p className='text-grayLight-900 font-medium text-sm'>
            {login}
          </p>
        );
      },
      header: 'Adres email / login'
    }),
    columnHelper.accessor('phoneNumber', {
      cell: (info) => {
        const phoneNumber = info.getValue();

        return (
          <p className='text-grayLight-900 font-normal text-sm'>
            {phoneNumber ? phoneNumber : 'Brak'}
          </p>
        );
      },
      header: 'Telefon'
    }),
    columnHelper.accessor('consumerId', {
      cell: (info) => {
        const consumerId = info.getValue();

        return (
          <p className="text-grayLight-900 font-normal text-sm">
            {consumerId}
          </p>
        );
      },
      header: "Identyfikator"
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const { userId } = info.row.original;
        const newPath = location.pathname.replace('employees-table', `single-employee/${userId}/balance-history`);
        return (
          <div className="flex gap-spacing-sm">
            <button
              onClick={() => {
                setDeleteElement({
                  id: userId,
                  alertIsOpen: true
                })
              }}
              className="font-semibold text-sm text-grayLight-600 bg-transparent border-none">
              Usuń
            </button>
            <Link to={newPath} onClick={() => {
              setId(userId);
              setMode('employee');
              fetchUsers();
            }}>
              <button
                className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
                Otwórz
              </button>
            </Link>
          </div>
        );
      }
    })
  ];

  useEffect(() => {
    fetchEmployee();
  }, [id]);

  return (
    <section className="EmployeeTable p-spacing-xl w-full">
      <TableContainer h={800} overflowY="auto" className="Branches__table bg-white rounded-lg">
        <DataTable
          extraThead={
            <Thead>
              <Th colSpan={columns.length + 1}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base normal-case text-grayLight-900"
                      >
                        Pracownicy
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm normal-case text-grayLight-700"
                      >
                        Lista pracowników
                      </Text>
                    </Box>
                  </div>
                  <div className="flex gap-spacing-lg">
                    <CustomChakraButton size="md" hierarchy="linkGray" iconPosition="onlyText"
                                        buttonProps={{
                                          type: 'button',
                                          onClick: () => {
                                            setShowEditStackPopUp(true)
                                          },
                                          disabled: selectedUsers.length === 0
                                        }}
                    >
                      Edytuj
                    </CustomChakraButton>
                    <CustomChakraButton size="md" hierarchy="primary" iconPosition="left" icon="plus"
                                        buttonProps={{
                                          type: 'button',
                                          onClick: () => {
                                            setAddEmployeeOrTeacherAlert({ mode: 'employee', isOpen: true })
                                          }
                                        }}
                    >
                      Dodaj użytkownika
                    </CustomChakraButton>
                  </div>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={employees}
        />
      </TableContainer>
      {deleteElement.alertIsOpen ? (
        <DeleteEmployeeOrTeacher
          isOpen={deleteElement.alertIsOpen}
          deleteId={deleteElement.id}
          onCancel={() => {
            fetchEmployee();
            setDeleteElement(defaultDeleteElement)
          }}
          onConfirm={() => {
            fetchEmployee();
            setDeleteElement(defaultDeleteElement)
          }}
          userType='employee'
        />
      ) : null}
      {addEmployeeOrTeacherAlert.isOpen ? (
        <AddEmployeeOrTeacher
          handleClose={() => {
            setAddEmployeeOrTeacherAlert((prev) => ({ ...prev, isOpen: false }));
            fetchEmployee();
          }}
          isOpen={addEmployeeOrTeacherAlert.isOpen}
          mode={addEmployeeOrTeacherAlert.mode} />
      ) : null}
      {showEditStackPopUp ? (
        <EditStackOfUser
          handleClose={() => {
            setShowEditStackPopUp(false);
            fetchEmployee();
          }}
          isOpen={showEditStackPopUp}
          usersIds={selectedUsers}
        />
      ) : null}
    </section>
  );
};

export default EmployeesTable;
