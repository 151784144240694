import { SideMenuOptionInterface } from "../../shared";
import IconComponent from "../IconComponent/IconComponent";
import { useLocation, Link } from "react-router-dom";

interface Props {
  menuOptions: SideMenuOptionInterface[];
}

const MobileMenu = ({ menuOptions }: Props) => {
  const location = useLocation();
  const midPoint = Math.floor(menuOptions.length / 2);
  const leftLinks = menuOptions.slice(0, midPoint);
  const rightLinks = menuOptions.slice(midPoint);

  const linkDivStyles = 'h-full flex flex-col items-center justify-end pt-spacing-lg pb-spacing-md gap-spacing-xxs box-border';

  const getFlexBasis = (noOfElements: number) => {
    return `${100 / (noOfElements + 1)}%`
  }

  const getParagraph = (text: string, isActive: boolean) => (
      <p
        className={`text-xs font-medium ${isActive ? "text-grayLight-900" : "text-grayLight-500"}`}
      >{text}</p>
  )

  const menuIcon = (link: SideMenuOptionInterface) => {
    const isActive = link.link ? location.pathname.includes(link.link) : false;
    return (
      <Link
        to={link.link || '#'}
        className={`${linkDivStyles} ${isActive ? 'active' : ''}`}
        style={{flexBasis: getFlexBasis(menuOptions.length)}}>
        <IconComponent iconName={link.icon} color={isActive ? '#182230' : undefined}/>
        {getParagraph(link.header, isActive)}
        <div
          style={{borderRadius: '2px'}}
          className={`w-5 border-t-4 ${isActive ? 'border-brand-300' : 'border-transparent'}`} />
      </Link>
    )
  }

  return (
    <div
      style={{
        zIndex: 9999,
      }}
      className="w-full h-full flex bg-white border-t box-border border-grayLight-100">
      {leftLinks.map(menuIcon)}
      <div
        className={linkDivStyles}
        style={{ flexBasis: getFlexBasis(menuOptions.length) }}>
        <div className='w-9 h-9 flex items-center justify-center rounded-xl bg-brand-300'>
          <IconComponent iconName='plus' />
        </div>
        {getParagraph('Zamów', false)}
        <div
          style={{ borderRadius: '2px' }}
          className={`w-5 border-t-4 ${location.pathname.includes('order') ? 'border-brand-300' : 'border-transparent'}`} />
      </div>
      {rightLinks.map(menuIcon)}
    </div>
  )
};

export default MobileMenu;