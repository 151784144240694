import React, { useState, useEffect } from 'react';
import { TableContainer, Thead, Th, Text, Box } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { useApi } from '../../../../context/ApiProvider';
import { DayOffInterface } from '../../../../shared';
import { DeletedElementInterface } from '../../../../shared/type/deletedElement.type';
import defaultAlertContext from '../../../../utils/defaultAlertContext';
import CustomAlert from '../../../CustomAlert/CustomAlert';
import CustomBadge from '../../../CustomBadge/CustomBadge';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../DataTable/DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import IconComponent from '../../../IconComponent/IconComponent';
import AddDayOff from './AddEditDayOff/AddDayOff';
import EditDayOff from './AddEditDayOff/EditDayOff';

const DaysOff = () => {
  const { id: tenant } = useParams();
  const { apiTenantFreeWorkingDayController } = useApi();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [freeWorkingDays, setFreeWorkingDays] = useState<DayOffInterface[]>([]);
  const [editedDayOff, setEditedDayOff] = useState<DayOffInterface | null>(null);
  const [deleteElement, setDeleteElement] = useState<DeletedElementInterface>(defaultAlertContext);

  const columnHelper = createColumnHelper<DayOffInterface>();

  const fetchDaysOff = async () => {
    setIsLoading(true);
    try {
      const result = await apiTenantFreeWorkingDayController('').get(`/${tenant}`);
      setFreeWorkingDays(result.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDaysOff();
  }, [tenant]);

  useEffect(() => {
    fetchDaysOff();
  }, []);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Nazwa'
    }),
    columnHelper.accessor('organisationUnits', {
      cell: (info) => {
        const classes = info.getValue();
        if (!classes.length) {
          return <p className="font-normal text-sm text-grayLight-600">Wszyscy</p>;
        }
        return (
          <div className="flex">
            {classes.map((singleUnit, index) => (
              <React.Fragment key={singleUnit.name + singleUnit.id}>
                <p className="font-normal text-sm text-grayLight-600">{singleUnit.name}</p>
                {index < classes.length - 1 && ", "}
              </React.Fragment>
            ))}
          </div>

        );
      },
      header: "Klasa"
    }),
    columnHelper.accessor("from", {
      cell: (info) => info.getValue(),
      header: "Od"
    }),
    columnHelper.accessor("to", {
      cell: (info) => info.getValue(),
      header: "Do"
    }),
    columnHelper.accessor('freeDay', {
      cell: (info) => {
        const value = info.getValue();
        return value ? (
          <CustomBadge size="md" color="success">
            Sprzedaż otwarta
          </CustomBadge>
        ) : (
          <CustomBadge size="md" color="error">
            Sprzedaż zamknięta
          </CustomBadge>
        );
      },
      header: 'Sprzedaż'
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const dayOff = info.row.original;
        return (
          <div className="flex gap-spacing-sm">
            <button
              className="font-semibold text-sm text-grayLight-600 bg-transparent border-none"
              onClick={() => {
                setDeleteElement({ id: dayOff.id, isOpen: true });
              }}
            >
              Usuń
            </button>
            <button
              className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none"
              onClick={(e) => {
                e.preventDefault();
                setEditedDayOff(dayOff);
                setIsEditModalOpen(true);
              }}
            >
              Edytuj
            </button>
          </div>
        );
      }
    })
  ];

  return (
    <section className="DaysOff p-spacing-xl w-full pb-spacing-6xl">
      <TableContainer h="100%" flex="1" minH="500px" className="Branches__table bg-white rounded-lg pb-spacing-8xl">
        <DataTable
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base normal-case text-grayLight-900"
                      >
                        Dodatkowe dni wolne / pracujące
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm normal-case text-grayLight-700"
                      >
                        W tym miejscu możesz skonfigurować dodatkowe dni wolne takie jak ferie
                        zimowe/letnie lub dodatkowe dni pracujące, na przykład, odrabianie święta w
                        sobotę. Pamiętaj, system automatycznie wylicza dni ustawowo wolne.
                      </Text>
                    </Box>
                  </div>
                  <CustomChakraButton
                    size="md"
                    hierarchy="primary"
                    iconPosition="left"
                    icon="plus"
                    iconColor='#292524'
                    buttonProps={{
                      disabled: Number(tenant) === 0,
                      onClick: () => {
                        fetchDaysOff();
                        setIsAddModalOpen(true);
                      }
                    }}
                  >
                    Dodaj
                  </CustomChakraButton>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={freeWorkingDays}
        />
      </TableContainer>
      <CustomAlert
        header="Usunąć okres wolny/pracujący?"
        content="Usunięcie dni wolnych lub pracujących spowoduje zmiany w kalendarzu przyszłych zamówień."
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Usuń"
        onConfirmAction={async () => {
          try {
            await apiTenantFreeWorkingDayController('').delete(`/${tenant}/${deleteElement.id}`);
          } catch (error) {
            console.warn(error);
          } finally {
            setDeleteElement(defaultAlertContext);
            fetchDaysOff();
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setDeleteElement(defaultAlertContext);
          fetchDaysOff();
        }}
        handleOpen={deleteElement.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
      />
      <EditDayOff
        isOpen={isEditModalOpen}
        onClose={() => {
          fetchDaysOff();
          setIsEditModalOpen(false);
        }}
        initData={
          editedDayOff || {
            id: 0,
            name: '',
            from: new Date().toISOString().split('T')[0],
            to: new Date().toISOString().split('T')[0],
            freeDay: false,
            organisationUnits: []
          }
        }
      />
      <AddDayOff isOpen={isAddModalOpen} onClose={() => {
        setIsAddModalOpen(false);
        fetchDaysOff();
      }} />
    </section>
  );
};

export default DaysOff;
