import { Divider } from "@chakra-ui/react";
import axios, { AxiosResponse, HttpStatusCode } from "axios";
import React, { useEffect, useState } from "react";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import useAuth from "../../../../hooks/useAuth";
import { AdminData } from "../../../../shared/type/administrator.type";
import handlePhoneNumberChange from "../../../../utils/handlePhoneNumberChange";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../Forms/FormInput/FormInput";

const defaultNewEmailObj = {email: undefined, password: undefined}

const AdminAccountSettings = () => {
  const { apiAdminDataControllerUrl, apiPrivateSecurityController, apiTenantAdminController } = useApi();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();

  const { auth } = useAuth();

  const [ adminData, setAdminData ] = useState<AdminData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    newEmailRequested: ''
  })
  const [isEmailEdited, setIsEmailEdited] = useState<boolean>(false)
  const [newEmail, setNewEmail] = useState<{
    email: string | undefined,
    password: string | undefined,
  }>(defaultNewEmailObj);
  const [ newPhoneNumber, setNewPhoneNumber ] = useState<string | undefined>();

  const fetchAdminData = async () => {
    try {
      const response: AxiosResponse<AdminData> = await apiAdminDataControllerUrl('read').get('');
      setAdminData(response.data)
      setNewPhoneNumber(handlePhoneNumberChange(response.data.phone || ''))
    } catch (error) {}
  };

  const changeEmail = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try{
      const response = await apiPrivateSecurityController('update-security-data').put('', {
        email: newEmail.email,
        oldPassword: newEmail.password,
        newPassword: newEmail.password
      });
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          status: 'success',
          title: 'Sukces',
          description: 'Poprawnie zaktualizowano profil'
        })
        fetchAdminData();
        setNewEmail(defaultNewEmailObj);
        setIsEmailEdited(false);
      }
    } catch (error) {
     if (axios.isAxiosError(error)) {
       if (error.response) {
         setAlertProperties({
           timeout: 5000,
           status: 'error',
           title: 'Błąd',
           description: `Nie udało się zaktualizować profilu. Błąd: ${error.response.data}`
         });
       }else{
         setAlertProperties({
           timeout: 5000,
           status: 'error',
           title: 'Błąd',
           description: `Nie udało się zaktualizować profilu.`
         });
       }
     }
    } finally {
      setShowAlert(true)
    }
  }

  const updateTenantAdminData = async () => {
    try {
      const response = await apiTenantAdminController('basic-data').put('', {
        userId: auth.loggedUserDto.id,
        firstName: adminData?.firstName,
        lastName: adminData?.lastName,
        phoneNumber: newPhoneNumber
      })
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          status: 'success',
          title: 'Sukces',
          description: 'Poprawnie zaktualizowano profil'
        })
      }
    } catch (error) {
      setAlertProperties({
        timeout: 5000,
        status: 'error',
        title: 'Błąd',
        description: 'Błąd aktualizacji profilu'
      })
      fetchAdminData();
    } finally {
      setShowAlert(true)
    }
  }

  useEffect(() => {
    fetchAdminData();
  }, []);

  return (
    <div className="flex flex-col gap-spacing-3xl">
      <div className='flex justify-between'>
        <div className='flex flex-col gap-spacing-xs'>
          <div>
            <h3 className='text-grayLight-900 text-lg font-semibold'>Twoje dane</h3>
          </div>
          <div>
            <p className='text-grayLight-600 text-sm font-normal'>Poniżej możesz zaktualizować swoje dane
              kontaktowe.</p>
          </div>
        </div>
        <div className='flex gap-spacing-lg'>
          <div>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="secondaryGray"
              size="md"
              buttonProps={{
              }}
            >
              Anuluj
            </CustomChakraButton>
          </div>
          <div>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="primary"
              size="md"
              buttonProps={{
                onClick: updateTenantAdminData
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-5'>
        <Divider />
        <div className='flex'>
          <div className='basis-2/12 flex items-start justify-start'>
            <p className='text-grayLight-700 font-semibold text-sm'>Imię i nazwisko</p>
          </div>
          <div className='basis-5/12 flex flex-col gap-spacing-3xl'>
            <div className='flex gap-spacing-3xl'>
              <div className='grow'>
                <FormInput
                  label=''
                  inputParams={{
                    disabled: false,
                    name: 'firstName',
                    value: adminData?.firstName,
                    onChange: (e) => {adminData && setAdminData((prev) => ({...prev, [e.target.name]: e.target.value}))}
                  }}
                />
              </div>
              <div className="grow">
                <FormInput
                  label=""
                  inputParams={{
                    disabled: false,
                    name: 'lastName',
                    value: adminData?.lastName,
                    onChange: (e) => {adminData && setAdminData((prev) => ({...prev, [e.target.name]: e.target.value}))}
                  }}
                />
              </div>
            </div>
            <div>
              <p className='text-grayLight-600 text-sm font-normal'>W celu zmiany tych danych wyślij wiadomość do
                Administratora w panelu Wiadomości</p>
            </div>
          </div>
          <div className='basis-5/12'></div>
        </div>
        <Divider />
        <form action="" className='flex flex-col gap-5' onSubmit={changeEmail}>
          <div className='flex'>
            <div className="basis-2/12">
              <p className="text-grayLight-700 font-semibold text-sm">Adres email</p>
            </div>
            <div className="basis-5/12">
              <FormInput
                label=''
                inputParams={{
                  disabled: true,
                  value: adminData?.email
                }}
              />
            </div>
            <div className="basis-5/12"></div>
          </div>
          {isEmailEdited && (
            <>
              <div className="flex">
                <div className="basis-2/12">
                  <p className="text-grayLight-700 font-semibold text-sm">Nowy adres email</p>
                </div>
                <div className="basis-5/12">
                  <FormInput
                    label=""
                    inputParams={{
                      type: 'email',
                      required: true,
                      value: newEmail.email,
                      autoComplete: 'off',
                      onChange: (e) => { setNewEmail((prev) => ({ ...prev, email: e.target.value })); }
                    }}
                  />
                </div>
                <div className="basis-5/12"></div>
              </div>
              <div className="flex">
                <div className="basis-2/12">
                  <p className="text-grayLight-700 font-semibold text-sm">Hasło</p>
                </div>
                <div className="basis-5/12">
                  <FormInput
                    label=""
                    inputParams={{
                      type: 'password',
                      required: true,
                      value: newEmail.password,
                      autoComplete: 'new-password',
                      onChange: (e) => { setNewEmail((prev) => ({ ...prev, password: e.target.value })); }
                    }}
                  />
                </div>
                <div className="basis-5/12"></div>
              </div>
            </>
          )}
          <div className="flex">
            <div className="basis-2/12 flex items-start justify-start">
            </div>
            <div className="basis-5/12 flex">
              {isEmailEdited ? (
                <>
                  <div>
                    <CustomChakraButton
                      iconPosition="onlyText"
                      hierarchy="linkGray"
                      size="md"
                      buttonProps={{
                        type: 'button',
                        onClick: () => {
                          setIsEmailEdited(false);
                          setNewEmail(defaultNewEmailObj);
                        }
                      }}
                    >
                      Anuluj
                    </CustomChakraButton>
                  </div>
                  <div>
                    <CustomChakraButton
                      iconPosition="onlyText"
                      hierarchy="linkGray"
                      size="md"
                      buttonProps={{
                        type: 'submit',
                      }}
                    >
                      Zapisz
                    </CustomChakraButton>
                  </div>
                </>
              ) : (
                <div>
                  <CustomChakraButton
                    iconPosition="onlyText"
                    hierarchy="linkGray"
                    size="md"
                    buttonProps={{
                      type: 'button',
                      onClick: () => {setIsEmailEdited(true)}
                    }}
                  >
                    Edytuj
                  </CustomChakraButton>
                </div>
              )}
            </div>
            <div className="basis-5/12"></div>
          </div>
        </form>
        <Divider />
        <div className="flex">
          <div className="basis-2/12 flex items-start justify-start">
            <p className="text-grayLight-700 font-semibold text-sm">Numer telefonu</p>
          </div>
          <div className="basis-5/12">
            <FormInput
              label=""
              inputParams={{
                onChange: (e) => {
                  e.target.value = handlePhoneNumberChange(e.target.value);
                  setNewPhoneNumber(e.target.value);
                },
                value: newPhoneNumber
              }}
            />
          </div>
          <div className='basis-5/12'></div>
        </div>
      </div>
    </div>
  )};
export default AdminAccountSettings;