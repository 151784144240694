import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import FormInput from '../../Forms/FormInput/FormInput';
import IconComponent from '../../IconComponent/IconComponent';
import { useResetPasswordContext } from '../../../context/ResetPasswordProvider';

const RecoverPassword = () => {
  const { email, setEmail, resetPassword } = useResetPasswordContext();
  const [redirect, setRedirect] = useState<boolean>(false);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await resetPassword();
      if (response === StatusCodes.OK) {
        setRedirect(true);
      }
    } catch (error: any) {
      switch (error.response?.status) {
        case StatusCodes.BAD_REQUEST:
        case StatusCodes.UNAUTHORIZED:
        case StatusCodes.NOT_FOUND:
        case StatusCodes.CONFLICT:
          console.warn('An expected error occurred');
          break;
        default:
          console.warn('An unexpected error occurred');
          break;
      }
    }
  };

  return (
    <div className="LoginPage__login-form w-full flex flex-col justify-between items-start">
      <div className="grid gap-8 m-auto w-full pl-container-padding-mobile pr-container-padding-mobile">
        <div className="LoginPage__header grid gap-spacing-3xl text-center">
          <IconComponent iconName="key" className="m-auto" />
          <div className="grid gap-3">
            <h2 className="font-semibold text-4xl text-grayLight-900">Zapomniane hasło?</h2>
            <p className="font-normal text-base text-grayLight-600 text-center">
              Spokojnie, każdemu się zdarza;)
              <br />
              <br />
              Wpisz adres email podany przy rejestracji a my wyślemy Ci instrukcję resetowania
              hasła.
            </p>
          </div>
        </div>
        <form className="max-w-2xl grid gap-spacing-3xl w-full" onSubmit={handleFormSubmit}>
          <FormInput
            label="Email*"
            inputParams={{
              placeholder: 'Wpisz adres email',
              type: 'email',
              required: true,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
              },
              value: email
            }}
          />
          <button
            style={{
              borderRadius: '10px',
            }}
            className="bg-brand-300 border-brand-300 py-2.5 px-4 font-semibold text-base"
            type="submit"
          >
            Resetuj hasło
          </button>
        </form>
        {redirect && <Navigate to="/login/recover/confirm" replace />}
        <div className="flex flex-row justify-start">
          <Link to="/login" className="flex m-auto">
            <IconComponent iconName="arrowLeft" />
            <p className="text-sm font-semibold text-grayLight-600 inline">Wróć do strony logowania</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
