import React from 'react';
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import CheckboxInput from "../../../../Forms/CheckboxInput/CheckboxInput";

interface CustomToolbarProps {
  label: string;
  onNavigate: (action: 'PREV' | 'NEXT' | 'TODAY' | Date) => void;
  onToggleSelectAll: (isSelected: boolean) => void;
  isSelectAllChecked: boolean;
  clearSelectedDates: () => void;
  addToBasked: () => void
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({ label, onNavigate, onToggleSelectAll, isSelectAllChecked, clearSelectedDates, addToBasked }) => {
  return (
    <div className="flex flex-col gap-spacing-xl">
      <div className="flex items-center justify-center gap-4 mb-4">
        <button
          onClick={() => onNavigate('PREV')}
          className="p-2 rounded-md"
        >
          &#8249;
        </button>
        <span className="text-xl font-semibold">{label}</span>
        <button
          onClick={() => onNavigate('NEXT')}
          className="p-2 rounded-md"
        >
          &#8250;
        </button>
      </div>
      <div className="flex justify-between px-spacing-xl lg:px-spacing-0  items-center pb-spacing-xl">
        <div className='flex'>
          <CheckboxInput
            text='Zaznacz cały miesiąc'
            checkboxParams={{
              isChecked: isSelectAllChecked,
              onChange: (e) => onToggleSelectAll(e.target.checked)
            }}
          />
        </div>
        <div className='flex gap-spacing-md'>
          <CustomChakraButton
            hierarchy='linkGray'
            iconPosition='left'
            icon='xClose'
            size='sm'
            buttonProps={{
              type: 'button',
              onClick: clearSelectedDates
            }}
          >Wyczyść kalendarz</CustomChakraButton>
          <CustomChakraButton
            hierarchy='primary'
            iconPosition='onlyText'
            icon='xClose'
            size='sm'
            buttonProps={{
              className: 'hidden lg:flex',
              type: 'button',
              onClick: addToBasked
            }}
          >Dodaj do koszyka</CustomChakraButton>
        </div>
      </div>
    </div>
  );
};

export default CustomToolbar;
