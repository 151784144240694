import { Divider } from "@chakra-ui/react";
import axios,{ AxiosResponse, HttpStatusCode } from "axios";
import React, { useEffect, useState } from "react";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { ParentData } from "../../../../shared/type/parent.type";
import handlePhoneNumberChange from "../../../../utils/handlePhoneNumberChange";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../Forms/FormInput/FormInput";

const defaultNewEmailObj = {email: undefined, password: undefined}

const ParentAccountSettings = () => {
  const { apiParentDataController, apiPrivateSecurityController } = useApi();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();

  const [ parentData, setParentData ] = useState<ParentData>()
  const [isEmailEdited, setIsEmailEdited] = useState<boolean>(false)
  const [newEmail, setNewEmail] = useState<{
    email: string | undefined,
    password: string | undefined,
  }>(defaultNewEmailObj);
  const [ newBankAccountNumber, setNewBankAccountNumber ] = useState<string | undefined>(parentData?.bankAccountNumber);
  const [ newPhoneNumber, setNewPhoneNumber ] = useState<string | undefined>();

  const fetchParentData = async () => {
    try {
      const response: AxiosResponse<ParentData> = await apiParentDataController('read').get('');
      setParentData(response.data)
      setNewPhoneNumber(handlePhoneNumberChange(response.data.phoneNumber))
      setNewBankAccountNumber(response.data.bankAccountNumber);
    } catch (error) {} finally {
    }
  };

  const changeEmail = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try{
      const response = await apiPrivateSecurityController('update-security-data').put('', {
        email: newEmail.email,
        oldPassword: newEmail.password,
        newPassword: newEmail.password
      });
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          status: 'success',
          title: 'Sukces',
          description: 'Poprawnie zaktualizowano profil'
        })
        fetchParentData();
        setNewEmail(defaultNewEmailObj);
        setIsEmailEdited(false);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setAlertProperties({
            timeout: 5000,
            status: 'error',
            title: 'Błąd',
            description: `Nie udało się zaktualizować profilu. Błąd: ${error.response.data}`
          });
        }else{
          setAlertProperties({
            timeout: 5000,
            status: 'error',
            title: 'Błąd',
            description: `Nie udało się zaktualizować profilu.`
          });
        }
      }
    } finally {
      setShowAlert(true)
    }
  }

  const changeBankAccountNumberOfPhoneNumber = async () => {
    try {
      const response: AxiosResponse<ParentData> = await apiParentDataController('update').put('', {
        ...parentData,
        phoneNumber: newPhoneNumber,
        bankAccountNumber: newBankAccountNumber
      });
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          status: 'success',
          title: 'Sukces',
          description: 'Poprawnie zaktualizowano profil'
        })
      }
    } catch (error: any) {
      setAlertProperties({
        timeout: 5000,
        title: "Błąd",
        description: `Nie udało się zaktualizować profilu: ${error.response.data.violations[0].message}`,
        status: "error"
      });

    } finally {
      setShowAlert(true)
    }
  }

  useEffect(() => {
    fetchParentData();
  }, []);

  return (
    <div className="flex flex-col gap-spacing-3xl pb-spacing-11xl">
      <div className='flex justify-between'>
        <div className='flex flex-col gap-spacing-xs'>
          <div>
            <h3 className='text-grayLight-900 text-lg font-semibold'>Twoje dane</h3>
          </div>
          <div>
            <p className='text-grayLight-600 text-sm font-normal'>Poniżej możesz zaktualizować swoje dane
              kontaktowe.</p>
          </div>
        </div>
        <div className='hidden lg:flex gap-spacing-lg'>
          <div>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="secondaryGray"
              size="md"
              buttonProps={{
                onClick: fetchParentData
              }}
            >
              Anuluj
            </CustomChakraButton>
          </div>
          <div>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="primary"
              size="md"
              buttonProps={{
                onClick: changeBankAccountNumberOfPhoneNumber
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-5'>
        <Divider />
        <div className='flex flex-col lg:flex-row'>
          <div className='basis-2/12 flex items-start justify-start'>
            <p className='text-grayLight-700 font-semibold text-sm'>Imię i nazwisko</p>
          </div>
          <div className='basis-5/12 flex flex-col gap-spacing-3xl'>
            <div className='flex flex-col lg:flex-row gap-spacing-3xl'>
              <div className='grow'>
                <FormInput
                  label=''
                  inputParams={{
                    disabled: true,
                    value: parentData?.firstName
                  }}
                />
              </div>
              <div className="grow">
                <FormInput
                  label=""
                  inputParams={{
                    disabled: true,
                    value: parentData?.lastName
                  }}
                />
              </div>
            </div>
            <div>
              <p className='text-grayLight-600 text-sm font-normal'>W celu zmiany tych danych wyślij wiadomość do
                Administratora w panelu Wiadomości</p>
            </div>
          </div>
          <div className='basis-5/12'></div>
        </div>
        <Divider />
        <form action="" className='flex flex-col gap-5' onSubmit={changeEmail}>
          <div className='flex flex-col lg:flex-row'>
            <div className="basis-2/12">
              <p className="text-grayLight-700 font-semibold text-sm">Adres email</p>
            </div>
            <div className="basis-5/12">
              <FormInput
                label=''
                inputParams={{
                  disabled: true,
                  value: parentData?.email
                }}
              />
            </div>
            <div className="basis-5/12"></div>
          </div>
          {isEmailEdited && (
            <>
              <div className="flex flex-col lg:flex-row">
                <div className="basis-2/12">
                  <p className="text-grayLight-700 font-semibold text-sm">Nowy adres email</p>
                </div>
                <div className="basis-5/12">
                  <FormInput
                    label=""
                    inputParams={{
                      type: 'email',
                      required: true,
                      value: newEmail.email,
                      autoComplete: 'off',
                      onChange: (e) => {
                        setNewEmail((prev) => ({ ...prev, email: e.target.value }));
                      }
                    }}
                  />
                </div>
                <div className="basis-5/12"></div>
              </div>
              <div className="flex flex-col lg:flex-row">
                <div className="basis-2/12">
                  <p className="text-grayLight-700 font-semibold text-sm">Hasło</p>
                </div>
                <div className="basis-5/12">
                  <FormInput
                    label=""
                    inputParams={{
                      type: 'password',
                      required: true,
                      value: newEmail.password,
                      autoComplete: 'new-password',
                      onChange: (e) => {
                        setNewEmail((prev) => ({ ...prev, password: e.target.value }));
                      }
                    }}
                  />
                </div>
                <div className="basis-5/12"></div>
              </div>
            </>
          )}
          <div className="flex">
            <div className="basis-2/12 flex items-start justify-start">
            </div>
            <div className="basis-full justify-end lg:basis-5/12 flex">
              {isEmailEdited ? (
                <>
                  <div>
                    <CustomChakraButton
                      iconPosition="onlyText"
                      hierarchy="linkGray"
                      size="md"
                      buttonProps={{
                        type: 'button',
                        onClick: () => {
                          setIsEmailEdited(false);
                          setNewEmail(defaultNewEmailObj);
                        }
                      }}
                    >
                      Anuluj
                    </CustomChakraButton>
                  </div>
                  <div>
                    <CustomChakraButton
                      iconPosition="onlyText"
                      hierarchy="linkGray"
                      size="md"
                      buttonProps={{
                        type: 'submit',
                      }}
                    >
                      Zapisz
                    </CustomChakraButton>
                  </div>
                </>
              ) : (
                <div>
                  <CustomChakraButton
                    iconPosition="onlyText"
                    hierarchy="linkGray"
                    size="md"
                    buttonProps={{
                      type: 'button',
                      onClick: () => {
                        setIsEmailEdited(true)
                      }
                    }}
                  >
                    Edytuj
                  </CustomChakraButton>
                </div>
              )}
            </div>
            <div className="basis-5/12"></div>
          </div>
        </form>
        <Divider />
        <div className="flex flex-col lg:flex-row">
          <div className="basis-2/12 flex items-start justify-start">
            <p className="text-grayLight-700 font-semibold text-sm">Numer telefonu</p>
          </div>
          <div className="basis-5/12">
            <FormInput
              label=""
              inputParams={{
                onChange: (e) => {
                  e.target.value = handlePhoneNumberChange(e.target.value);
                  setNewPhoneNumber(e.target.value);
                },
                value: newPhoneNumber
              }}
            />
          </div>
          <div className='basis-5/12'></div>
        </div>
        <Divider />
        <div className="flex flex-col lg:flex-row">
          <div className="basis-2/12 flex items-start justify-start">
            <p className="text-grayLight-700 font-semibold text-sm">Numer konta</p>
          </div>
          <div className="basis-5/12">
            <FormInput
              label=""
              inputParams={{
                maxLength: 32,
                onChange: (e) => {
                  setNewBankAccountNumber(e.target.value)
                },
                value: newBankAccountNumber
              }}
            />
          </div>
          <div className='basis-5/12'></div>
        </div>
      </div>
      <div className='flex lg:hidden flex-col gap-spacing-lg'>
        <div className='w-full flex'>
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="primary"
            size="md"
            buttonProps={{
              className: 'grow',
              onClick: changeBankAccountNumberOfPhoneNumber
            }}
          >
            Zapisz zmiany
          </CustomChakraButton>
        </div>
        <div className="w-full flex">
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="linkGray"
            size="md"
            buttonProps={{
              className: 'grow',
              onClick: fetchParentData
            }}
          >
            Anuluj
          </CustomChakraButton>
        </div>
      </div>
    </div>
  )
};

export default ParentAccountSettings;