import { AxiosResponse, HttpStatusCode } from "axios";
import { Select } from "chakra-react-select";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { useKidConsumerContext } from "../../../../../../context/KidConsumerProvider";
import { OrganisationUnitInterface } from "../../../../../../shared";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import CustomInput from "../../../../../CustomInput/CustomInput";
import FormInput from "../../../../../Forms/FormInput/FormInput";

const KidData = () => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { kid, setKid, setSaveButtonState, fetchKidConsumer } = useKidConsumerContext();
  const [ newOrganisationUnitId, setNewOrganisationUnitId ] = useState<number>(0);
  const { apiTenantOrganisationUnitController, apiTenantConsumerController } = useApi();
  const {id: tenantId} = useParams<{id: string}>();

  const [ organisationUnits, setOrganisationUnits] = useState<OrganisationUnitInterface[]>([]);

  const fetchOrganisationUnits = async () => {
    try {
      const response: AxiosResponse<OrganisationUnitInterface[]> = await apiTenantOrganisationUnitController('children').get(`/${tenantId}`);
      setOrganisationUnits(response.data);
    } catch (error) {
      console.error(error)
    }
  };

  const handleUpdateUser = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const response = await apiTenantConsumerController('').put(`/${kid.consumerDto.id}`, {
        tenantId: tenantId,
        firstName: kid.consumerDto.firstName,
        lastName: kid.consumerDto.lastName,
        organisationUnitId: newOrganisationUnitId,
        cardNumber: kid.consumerDto.cardNumber,
      });
      if(response.status === HttpStatusCode.Created){
        setAlertProperties({
          status: 'success',
          title: 'Sukces',
          description: 'Poprawnie zaktualizowano dziecko',
          timeout: 2000
        });
        setShowAlert(true);
      }
    } catch (error: any) {
      setAlertProperties({
        status: 'error',
        title: 'Błąd',
        description: `Błąd aktualizacji dziecka. ${error.response.data.errorMessage || ''}`,
        timeout: 2000
      });
      setShowAlert(true);
    }
  }

  useEffect(() => {
    fetchOrganisationUnits();
  }, [tenantId]);

  useEffect(() => {
    if (organisationUnits.length > 0 && kid.consumerDto.organisationUnitName) {
      const currentUnit = organisationUnits.find(
        (unit) => unit.name === kid.consumerDto.organisationUnitName
      );
      if (currentUnit) {
        setNewOrganisationUnitId(currentUnit.id);
      }
    }
  }, [organisationUnits, kid.consumerDto.organisationUnitName]);

  const selectedOrganisationUnit = organisationUnits.find(unit => unit.id === newOrganisationUnitId);

  return (
    <form onSubmit={handleUpdateUser} className="ParentData_formContainer pt-spacing-xl flex flex-col gap-spacing-5xl">
      <div className='flex'>
        <div className='basis-3/5 flex flex-col gap-spacing-3xl'>
          <FormInput
            label="Imię"
            placeholder="Podaj imię"
            inputParams={{
              name: 'firstName',
              value: kid?.consumerDto.firstName,
              onChange: (e) => {
                let newConsumerDto = { ...kid.consumerDto };
                newConsumerDto.firstName = e.target.value;
                setKid(prev => ({ ...prev, consumerDto: newConsumerDto }))
                setSaveButtonState(true);
              },
              required: true,
              minLength: 3,
            }}
          />
          <FormInput
            label="Nazwisko"
            placeholder="Podaj nazwisko"
            inputParams={{
              name: 'lastName',
              value: kid?.consumerDto.lastName,
              onChange: (e) => {
                let newConsumerDto = { ...kid.consumerDto };
                newConsumerDto.lastName = e.target.value;
                setKid(prev => ({ ...prev, consumerDto: newConsumerDto }))
                setSaveButtonState(true);
              },
              required: true,
              minLength: 3,
            }}
          />
          <div className="flex flex-col gap-spacing-md">
            <label className="text-sm font-medium text-grayLight-700">Klasa</label>
            <Select
              colorScheme="gray"
              placeholder="Wybierz"
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
              }}
              value={selectedOrganisationUnit ? {
                label: selectedOrganisationUnit.name,
                value: selectedOrganisationUnit.id
              } : null}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setNewOrganisationUnitId(selectedOption.value)
                }
              }}
              options={organisationUnits.map((unit) => ({
                ...unit,
                label: unit.name,
                value: unit.id
              }))}
            />
          </div>
          <CustomInput
            type="default"
            size="md"
            destructive={false}
            label="Numer identyfikatora"
            inputParams={{
              name: 'cardNumber',
              value: kid.consumerDto.cardNumber || '',
              onChange: (e) => {
                const target = e.target as HTMLInputElement;
                const value = target.value.replace(/\D/g, '');
                let newConsumerDto = { ...kid.consumerDto };
                newConsumerDto.cardNumber = value;
                setKid((prev) => ({ ...prev, consumerDto: newConsumerDto }));
                setSaveButtonState(true);
              },
              pattern: "\\d{10}",
              title: "Numer identyfikatora musi składać się z dokładnie 10 cyfr lub może być pusty.",
              maxLength: 10,
              required: false,
            }}
          />

          <div className="flex flex-col">
            <label className="text-grayLight-700 text-sm">Uwagi</label>
            <textarea
              cols={30}
              rows={3}
              style={{ resize: "none" }}
              value={kid.consumerDto.remarks || ""}
              onChange={(e) => {
                let newConsumerDto = { ...kid.consumerDto };
                newConsumerDto.remarks = e.target.value;
                setKid((prev) => ({ ...prev, consumerDto: newConsumerDto }));
                setSaveButtonState(true);
              }}
              maxLength={500}
              className="border border-gray-300 rounded-lg p-spacing-lg"
            />
            <div className="flex justify-between">
              <label className="text-grayLight-700 text-sm">maks. 500 znaków</label>
              <label className="text-grayLight-700 text-sm">{kid.consumerDto.remarks?.length}/500</label>
            </div>
          </div>
        </div>
        <div className="basis-2/5 flex flex-col gap-spacing-3xl">
          <div className="basis-2/5 flex items-start justify-end">
            <div className="AdministratorSettings__actionButtons flex gap-spacing-lg">
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="tertiaryColor"
                size="md"
                buttonProps={{
                  disabled: !setSaveButtonState,
                  type: "button",
                  onClick: fetchKidConsumer
                }}
              >
                Anuluj
              </CustomChakraButton>
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="primary"
                size="md"
                buttonProps={{
                  disabled: !setSaveButtonState,
                  type: 'submit',
                }}
              >
                Zapisz zmiany
              </CustomChakraButton>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

export default KidData;