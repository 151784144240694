import React, { ReactNode, useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  useMediaQuery,
  Select,
  MenuItem,
  MenuDivider
} from "@chakra-ui/react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useTenantIdContext } from "../../../context/TenantIdProvider";
import IconComponent from '../../IconComponent/IconComponent';
import translateRole from '../../../utils/translateRole';
import useAuth from '../../../hooks/useAuth';

import './WideHeader.css';

interface Props {
  dropdownMenuList: ReactNode;
  showLogo?: boolean;
}

const WideHeader = ({ dropdownMenuList, showLogo = true }: Props) => {
  const { auth, logout } = useAuth();
  const { setTenantId } = useTenantIdContext();
  const { admin, childOwner, tenantAdmin, superAdmin } = auth.loggedUserDto;
  const { id: tenant } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [isLessThanOrEqual1024] = useMediaQuery('(max-width: 1024px)');
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (tenant === 'undefined' && auth.availableTenants.length > 0) {
      const defaultTenantId = auth.availableTenants[0].id;
      setTenantId(defaultTenantId.toString());
      const updatedUrl = window.location.pathname.replace('/id/undefined', `/id/${defaultTenantId}`);
      navigate(updatedUrl);
    } else {
      setTenantId(tenant);
    }
  }, [tenant, auth.availableTenants, navigate, setTenantId]);

  const handleTenantChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newId = event.target.value;
    const currentUrl = window.location.pathname;
    const regex = /\/id\/\d+/;

    let updatedUrl;
    if (regex.test(currentUrl)) {
      updatedUrl = currentUrl.replace(regex, `/id/${newId}`);
    } else {
      updatedUrl = currentUrl.replace('/id/undefined', `/id/${newId}`);
    }
    navigate(updatedUrl);
    setTenantId(newId);
  };

  const userInfo = (userCircle: boolean) => (
    <div className="Header_userContainer flex items-center">
      {userCircle && (
        <div className="Header_userIcon w-10 h-10 flex justify-center items-center cursor-pointer p-spacing-md">
          <IconComponent iconName="userCircle" />
        </div>
      )}
      <div className="Header_userInfo">
        <p className="Header_userInfo__name text-base font-semibold">
          {auth.loggedUserDto.firstName}
        </p>
        <p className="Header__userInfo__role font-normal">
          {translateRole({
            admin,
            childOwner,
            tenantAdmin,
            superAdmin
          })}
        </p>
      </div>
    </div>
  );

  return (
    <header className="Header flex flex-row pr-8 pl-4">
      {showMenu && (
        <div className="w-screen h-screen fixed top-0 left-0 bg-white z-50">
          <div className="flex flex-row pr-8 pl-4">
            {showLogo === true && (
              <div className="Header__selectContainer">
                <IconComponent iconName="logo" className="Header__logoContainer mr-spacing-xl" />
              </div>
            )}
            <div
              className="flex flex-1 justify-end items-center"
              onClick={() => {
                setShowMenu(false);
              }}
            >
              <IconComponent iconName="menu" className="mr-spacing-xl" />
            </div>
          </div>
          <div className="ml-2">
            {userInfo(false)}
            <div className="grid grid-rows-3 gap-spacing-xl pt-spacing-3xl pb-spacing-3xl border-t border-b border-grayLight-200">
              <Link
                to="#"
                className="flex items-center pr-spacing-3xl pl-spacing-3xl font-semibold text-base"
              >
                <IconComponent iconName="message" className="mr-spacing-xl" />
                Wiadomość
              </Link>
              <Link
                to="#"
                className="flex items-center pr-spacing-3xl pl-spacing-3xl font-semibold text-base"
              >
                <IconComponent iconName="settings" className="mr-spacing-xl" />
                Ustawienia
              </Link>
            </div>
            <button
              type="button"
              onClick={logout}
              className="flex items-center pr-spacing-3xl pl-spacing-3xl font-semibold text-base bg-none border-none text-error-700 mt-spacing-md"
            >
              {' '}
              Wyloguj
              <IconComponent iconName="logOutDoor" className="ml-spacing-xl" />
            </button>
          </div>
        </div>
      )}
      <div className="Header__selectContainer flex justify-start items-center">
        {showLogo && (
          <IconComponent iconName="logo" className='Header__logoContainer' />
        )}
        <div className="h-20"/>
        <Select onChange={handleTenantChange} value={tenant || auth.availableTenants[0]?.id}>
          {auth.availableTenants.map((tenant) => (
            <option key={tenant.id} value={tenant.id}>
              {tenant.name}
            </option>
          ))}
        </Select>
      </div>
      {isLessThanOrEqual1024 && (
        <div
          className="flex flex-1 justify-end items-center"
          onClick={() => {
            setShowMenu(true);
          }}
        >
          <IconComponent iconName="menu" className="mr-spacing-xl" />
        </div>
      )}
      <div className="Header__actionContainer flex-1 justify-end hidden lg:flex">
        <div className="Header_userAction flex justify-center">
          <div className="Header_ringIconContainer flex justify-center items-center cursor-pointer">
            <Menu>
              <MenuButton as={Box} aria-label="Options" className="cursor-pointer flex items-center">
                <IconComponent iconName="bell" className="WideHeader__logo" />
              </MenuButton>
              <MenuList zIndex="dropdown" boxShadow="lg">
                <MenuItem>
                  Powiadomienie 1
                </MenuItem>
                <MenuDivider />
                <MenuItem>
                  Powiadomienie 2
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <Menu>
            <MenuButton as={Box} aria-label="Options" className="cursor-pointer flex items-center">
              {userInfo(true)}
            </MenuButton>
            <MenuList zIndex="dropdown" boxShadow="lg">
              {dropdownMenuList}
            </MenuList>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default WideHeader;