import { Divider } from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { useAlertContext } from "../../context/AlertProvider";
import { useApi } from "../../context/ApiProvider";
import CustomChakraButton from "../CustomChakraButton/CustomChakraButton";
import FormInput from "../Forms/FormInput/FormInput";
import IconComponent from "../IconComponent/IconComponent";

const defaultData = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const ChangePasswordSettings = () => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { apiPrivateSecurityController } = useApi();

  const [ newData, setNewData ] = useState<{
    oldPassword: string | undefined,
    newPassword: string | undefined,
    confirmPassword: string | undefined,
  }>(defaultData)

  const [ hidePassword, setHidePassword ] = useState<{
    oldPassword: boolean,
    newPassword: boolean,
    confirmPassword: boolean,
  }>({
    oldPassword: true,
    newPassword: true,
    confirmPassword: true
  })

  const changePassword = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try{
      const response = await apiPrivateSecurityController('update-security-data').put('', {
        oldPassword: newData?.oldPassword,
        newPassword: newData?.newPassword,
      });
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          status: 'success',
          title: 'Sukces',
          description: 'Poprawnie zaktualizowano profil'
        })
        setNewData((prev) => ({
          oldPassword: prev.newPassword,
          newPassword: '',
          confirmPassword: '',
        }))
      }
    } catch (error: any) {
      const getErrorMessage = (): string => {
        if(error.response.data.violations) {
          return `${error.response?.data.violations[0]?.message}`
        }
        return `${error.response.data}`
      }
      setAlertProperties({
        timeout: 2000,
        status: 'error',
        title: 'Błąd',
        description: `Nie udało się zaktualizować profilu. ${getErrorMessage()}`
      })
    } finally {
      setShowAlert(true)
    }
  }

  return (
    <form className="flex flex-col gap-spacing-3xl pb-spacing-11xl" onSubmit={changePassword}>
      <div className='flex justify-between'>
        <div className='flex flex-col gap-spacing-xs'>
          <div>
            <h3 className='text-grayLight-900 text-lg font-semibold'>Zmiana hasła</h3>
          </div>
          <div>
            <p className='text-grayLight-600 text-sm font-normal'>Poniżej możesz zaktualizować swoje hasło.</p>
          </div>
        </div>
        <div className='hidden lg:flex gap-spacing-lg'>
          <div>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="secondaryGray"
              size="md"
              buttonProps={{
                type: 'button',
                onClick: () => {
                  setNewData(defaultData)
                }
              }}
            >
              Anuluj
            </CustomChakraButton>
          </div>
          <div>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="primary"
              size="md"
              buttonProps={{
                type: 'submit',
                disabled: newData.newPassword !== newData.confirmPassword || newData.newPassword?.length === 0
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-5'>
        <Divider />
        <div className='flex lg:flex-row flex-col'>
          <div className="basis-2/12">
            <p className="text-grayLight-700 font-semibold text-sm">Aktualne hasło</p>
          </div>
          <div className="basis-5/12 relative">
            <FormInput
              label=""
              placeholder="Wpisz obecne hasło"
              inputParams={{
                required: true,
                type: hidePassword.oldPassword ? 'password' : 'text',
                value: newData?.oldPassword,
                onChange: (e) => {
                  setNewData((prev) => ({ ...prev, oldPassword: e.target.value }));
                }
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "calc(50% + 4px)",
                right: "10px",
                transform: "translateY(-50%)",
                pointerEvents: "auto"
              }}
              className="flex items-center cursor-pointer"
              onClick={() => {
                setHidePassword((prev) => ({ ...prev, oldPassword: !prev.oldPassword }))
              }}
            >
              <IconComponent iconName="hidePassword" className="h-4.5 w-4.5" />
            </div>
          </div>
          <div className="basis-5/12"></div>
        </div>
        <Divider />
        <div className="flex lg:flex-row flex-col">
          <div className="basis-2/12">
          <p className="text-grayLight-700 font-semibold text-sm">Nowe hasło</p>
          </div>
          <div className="basis-5/12 relative">
            <FormInput
              placeholder="Wpisz nowe hasło"
              label=""
              inputParams={{
                required: true,
                type: hidePassword.newPassword ? 'password' : 'text',
                value: newData?.newPassword,
                onChange: (e) => {
                  setNewData((prev) => ({ ...prev, newPassword: e.target.value }))
                }
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 'calc(50% + 4px)',
                right: '10px',
                transform: 'translateY(-50%)',
                pointerEvents: 'auto'
              }}
              className='flex items-center cursor-pointer'
              onClick={() => {
                setHidePassword((prev) => ({ ...prev, newPassword: !prev.newPassword }))
              }}
            >
              <IconComponent iconName='hidePassword' className='h-4.5 w-4.5' />
            </div>
          </div>

          <div className="basis-5/12"></div>
        </div>
        <Divider />
        <div className="flex lg:flex-row flex-col">
          <div className="basis-2/12">
          <p className="text-grayLight-700 font-semibold text-sm">Powtórz hasło</p>
          </div>
          <div className="basis-5/12 relative">
            <FormInput
              placeholder='Powtórz nowe hasło'
              label=''
              inputParams={{
                required: true,
                type: hidePassword.confirmPassword ? 'password' : 'text',
                value: newData?.confirmPassword,
                onChange: (e) => {
                  setNewData((prev) => ({ ...prev, confirmPassword: e.target.value }))
                }
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 'calc(50% + 4px)',
                right: '10px',
                transform: 'translateY(-50%)',
                pointerEvents: 'auto'
              }}
              className='flex items-center cursor-pointer'
              onClick={() => {
                setHidePassword((prev) => ({ ...prev, confirmPassword: !prev.confirmPassword }))
              }}
            >
              <IconComponent iconName='hidePassword' className='h-4.5 w-4.5' />
            </div>
          </div>
          <div className="basis-5/12"></div>
        </div>
        <Divider />
        <div className='flex lg:hidden flex-col gap-spacing-lg'>
          <div className="w-full flex">
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="primary"
              size="md"
              buttonProps={{
                type: 'submit',
                className: 'grow',
                disabled: newData.newPassword !== newData.confirmPassword || newData.newPassword?.length === 0
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </div>
          <div className='w-full flex'>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="secondaryGray"
              size="md"
              buttonProps={{
                type: 'button',
                className: 'grow',
                onClick: () => {
                  setNewData(defaultData)
                }
              }}
            >
              Anuluj
            </CustomChakraButton>
          </div>
        </div>
      </div>
    </form>
  )
};

export default ChangePasswordSettings;