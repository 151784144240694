import { Alert, AlertIcon, AlertTitle, AlertDescription, Box, CloseButton } from "@chakra-ui/react";
import React, { useEffect, useState, ReactNode } from "react";

type StatusType = 'info' | 'warning' | 'success' | 'error' | 'neutral';

interface Props {
  onClose: () => void;
  timeout: number;
  title?: string | ReactNode;
  description?: string | ReactNode;
  status: StatusType
}

const CustomAlertDialogComponent = ({ status, title, description, onClose, timeout }: Props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          onClose();
        }, 500); // Duration of fade out effect
      }, timeout);

      // Clean up timer on unmount or timeout change
      return () => clearTimeout(timer);
    }
  }, [timeout, onClose]);

  const getBackgroundColor = (status: StatusType) => {
    switch (status) {
      case "success": return '#079455'
      case "warning": return '#FFF7E6'
      case "error": return '#B42318'
      default: return '#FFFFFF'
    }
  }

  const getTextColor = (status: StatusType): {header: string, supportive: string} => {
    switch (status) {
      case "success": return {header: '#FFFFFF', supportive: '#FFFFFF'};
      case "warning": return {header: '#292524', supportive: '#44403C'};
      case "error": return {header: '#FFFFFF', supportive: '#FFFFFF'};
      default: return {header: '#344054', supportive: '#475467'};
    }
  }

  return (
    <div
      className={`w-full justify-end flex lg:pl-12 lg:pr-12 fixed left-0 bottom-10 z-50 transition-opacity duration-500 ${visible ? 'opacity-100' : 'opacity-0'}`}
      style={{ zIndex: 9999 }}
    >
      <div className="flex basis-1/2 w-full">
        <Alert
          // status={status}
          backgroundColor={getBackgroundColor(status)}
          className='rounded-xl bg-red-300 flex flex-row justify-between'
        >
          <div className='flex'>
            <AlertIcon
              color={getTextColor(status).supportive}
            />
            <Box>
              {title && (
                <AlertTitle
                  className='text-sm font-semibold'
                  color={getTextColor(status).header}
                >{title}</AlertTitle>
              )}
              {description && (
                <AlertDescription
                  color={getTextColor(status).supportive}
                >
                  {description}
                </AlertDescription>
              )}
            </Box>
          </div>
          <CloseButton
            alignSelf='flex-start'
            position='relative'
            color={getTextColor(status).supportive}
            right={-1}
            top={-1}
            onClick={onClose}
          />
        </Alert>
      </div>
    </div>
  );
};

export default CustomAlertDialogComponent;
