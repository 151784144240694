import { MenuItem, Divider } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import ParentAbsences from "../../component/ChildOwnerComponents/ParentAbsences/ParentAbsences";
import ParentAssignedAccounts from "../../component/ChildOwnerComponents/ParentAssignedAccounts/ParentAssignedAccounts";
import ParentFoodMenu from "../../component/ChildOwnerComponents/ParentFoodMenu/ParentFoodMenu";
import SingleMessage
  from "../../component/ChildOwnerComponents/ParentMailBox/ParentMessage/SingleMessage/SingleMessage";
import SingleNotification
  from "../../component/ChildOwnerComponents/ParentMailBox/ParentNotification/SingleNotification/SingleNotification";
import ParentMainPage from "../../component/ChildOwnerComponents/ParentMain/ParentMain";
import ParentMessages from "../../component/ChildOwnerComponents/ParentMailBox/ParentMailBox";
import ParentMealPlan from "../../component/ChildOwnerComponents/ParentMealPlan/ParentMealPlan";
import ParentOrder from "../../component/ChildOwnerComponents/ParentOrder/ParentOrder";
import ParentOrderHistory from "../../component/ChildOwnerComponents/ParentOrderHistory/ParentOrderHistory";
import ParentOrders from "../../component/ChildOwnerComponents/ParentOrders/ParentOrders";
import ParentSettings from "../../component/ChildOwnerComponents/ParentSettings/ParentSettings";
import ParentHeader from "../../component/Headers/ParentHeader/ParentHeader";
import IconComponent from "../../component/IconComponent/IconComponent";
import { useApi } from "../../context/ApiProvider";
import { ParentBasketContextProvider } from "../../context/ParentBasketProvider";
import { ParentOrderContextProvider } from "../../context/ParentOrderContext";
import { useSideNavContext } from "../../context/SideNavProvider";
import { useThemeContext } from "../../context/ThemeProvider";
import useAuth from "../../hooks/useAuth";
import { SideMenuOptionInterface } from "../../shared";

const ChildOwner = () => {
  const { setNavOptions, toggleShowNav, showNav, setToggleMode } = useSideNavContext();
  const { apiPrivateSecurityController } = useApi();
  const { logout } = useAuth();
  const { isMobile } = useThemeContext()
  const navigate = useNavigate();

  const menuOptions: SideMenuOptionInterface[] = [
    {
      header: 'Strona Główna',
      icon: 'happyHomeIcon',
      link: 'main'
    },
    {
      header: 'Nieobecności',
      icon: 'calendar',
      link: 'absences'
    },
    {
      header: 'Jadłospis',
      icon: 'cutlery',
      link: 'meal-plan'
    },
    {
      header: 'Powiązane konta',
      icon: 'users',
      link: 'assigned-accounts'
    },
    {
      header: 'Historia zamówień',
      icon: 'history',
      link: 'order-history'
    },
    {
      header: 'Wiadomości',
      icon: 'message',
      link: 'mail-box/messages'
    }
  ];

  const menuList = (
    <>
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="user" className="w-full h-full" />
          </div>
        }
        onClick={function(){
          navigate('/parent/settings')
        }}
      >
        Profil
      </MenuItem>
      <Divider/>
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="logOut" className="w-full h-full" />
          </div>
        }
        onClick={async function(){
          await apiPrivateSecurityController('logout').get('')
          logout()
        }}
      >
        Wyloguj się
      </MenuItem>
    </>
  );

  useEffect(() => {
    setToggleMode('minimal');
    toggleShowNav(true);
    setNavOptions(menuOptions);
  }, []);

  return (
    <ParentBasketContextProvider>
      <ParentOrderContextProvider>
        <section className={`ParentDashboard flex flex-row h-screen w-full`}>
          <div className="ParentDashboard__contextContainer h-full w-full ">
            <div className="ParentDashboard__headerContainer border-b border-brand-600">
              <ParentHeader notificationList={null} dropdownMenuList={menuList} /> {showNav}
            </div>
            <div className={`ParentDashboard__greetingsContainer h-full overflow-auto ${isMobile ? 'h-[calc(100%-72px)]' : 'h-screen'}`}>
              <div className="ParentDashboard__contextContainer h-full">
                <Routes>
                  {/* tpay routes */}
                  <Route path="/main/tpay/success" element={<ParentMainPage />} />
                  <Route path="/main/tpay/error" element={<ParentMainPage />} />
                  {/* other routes */}
                  <Route path="/main/*" element={<ParentMainPage />} />
                  <Route path="/settings/*" element={<ParentSettings />} />
                  <Route path="/order/*" element={<ParentOrder />} />
                  <Route path="/orders/*" element={<ParentOrders />} />
                  <Route path="/food-menu/*" element={<ParentFoodMenu />} />
                  <Route path="/assigned-accounts/*" element={<ParentAssignedAccounts />} />
                  <Route path="/absences/*" element={<ParentAbsences />} />
                  <Route path="/mail-box/messages/message/:id/*" element={<SingleMessage />} />
                  <Route path="/mail-box/notifications/notification/:id" element={<SingleNotification />} />
                  <Route path="/mail-box/*" element={<ParentMessages />} />
                  <Route path="/order-history/*" element={<ParentOrderHistory />} />
                  <Route path="/meal-plan" element={<ParentMealPlan />} />
                </Routes>
              </div>
            </div>
          </div>
        </section>
      </ParentOrderContextProvider>
    </ParentBasketContextProvider>
  );
};

export default ChildOwner;
